import { Box } from '@material-ui/core';
import { Polygon, Properties, point } from '@turf/helpers';
import { Feature as MapFeature } from 'geojson';
import * as MapboxGl from 'mapbox-gl';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState, useContext, useEffect } from 'react';
import { Feature, Source, Layer, Popup } from 'react-mapbox-gl';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { useConfig, LatLon, useCurrentLocation, MapBoundsContext } from '@terragotech/gen5-shared-components';
import Map, { MapItem } from '../../Map/component/Map';
import { BaseLocationMapProps, useLocationStyles } from './CommonEditorUtils';
import { makeStyles } from '@material-ui/core/styles';
import { isCoordinatesValid } from '@terragotech/gen5-shared-utilities';
import { useTheme } from '@material-ui/core';
import HideNonSelectableButtons from '../../Map/component/HideNonSelectableButton';
import { colors } from '../../../styles/theme';
import MapButtons from './MapButtons';
import useHideNonSelectable from '../../Map/component/useHideNonSelectable';
import { useSetRecoilState } from 'recoil';
import { mapSelectBoundsState } from '../../../recoil/atoms/mapMaintenance';
import { getFitBounds } from './measurementUtils';

interface TGPointEditorProps extends BaseLocationMapProps {
  capturedLocation: GeoJSON.Point | GeoJSON.Point[] | null | undefined;
  setCapturedLocation: Dispatch<SetStateAction<GeoJSON.Point | GeoJSON.Point[] | null | undefined>>;
  setManualMode: Dispatch<SetStateAction<boolean>>;
  maximum: number | undefined;
  unlimited: boolean | undefined;
  selectedPoint:
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined;
  setSelectedPoint: React.Dispatch<
    React.SetStateAction<
      | {
          coordinates: number[];
          indexOfCoords: number;
        }
      | undefined
    >
  >;
  selectedPointRef: React.MutableRefObject<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >;
  setOpenAddLocationAlert: React.Dispatch<React.SetStateAction<boolean>>;
  isMobileView?: boolean;
}
interface Point {
  type: 'Point';
  coordinates: [number, number];
}
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.white95,
    color: 'black',
    fontSize: 14,
    fontWeight: 400,
    padding: '14px 18px',
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    '& .MuiSnackbarContent-message': {
      padding: 0,
    },
  },
  popup: {
    backgroundColor: colors.lightGoldenrodYellow,
    background: colors.lightGoldenrodYellow,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontHeight: 300,
    fontSize: 11,
    color: colors.black0,
    height: 50,
    width: 100,
  },
  popupWrapper: {
    '& .mapboxgl-popup-content': {
      backgroundColor: colors.lightGoldenrodYellow,
      maxWidth: 118,
      maxHeight: 38,
      fontHeight: 300,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontSize: '11px',
      padding: '0px !important',
      paddingLeft: '8px !important',
    },
    '& .mapboxgl-popup-tip': {
      border: 0,
    },
    '&:hover': {
      zIndex: `99999 !important`,
    },
  },
  popupItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: colors.black0,
    lineHeight: 1,
    marginTop: 0,
    marginBottom: 0,
    height: 30,
  },
  popupItemValue: {
    fontSize: '13px',
  },
}));
export const TGMultiplePointsAdder: React.FC<TGPointEditorProps> = props => {
  const {
    setCapturedLocation,
    capturedLocation,
    setManualMode,
    maximum,
    unlimited,
    selectedPoint,
    setSelectedPoint,
    selectedPointRef,
    setOpenAddLocationAlert,
    isMobileView,
    setIsValidLocation,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  // get all assets to display based on filters
  const { aggregateDefinitions, initialMapExtents } = useConfig();
  const { currentZoomLevel: contextCurrentZoomLevel } = useContext(MapBoundsContext);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(contextCurrentZoomLevel);
  const { assetData, handleHidingNonSelectableRecords, hiding, visibleAggregateTypesNames } = useHideNonSelectable();
  const setMapBounds = useSetRecoilState(mapSelectBoundsState);
  const visibleRecordKeys = useMemo(
    () => visibleAggregateTypesNames.map(name => aggregateDefinitions.find(agg => agg.name === name)?.queryKey),
    [visibleAggregateTypesNames, aggregateDefinitions]
  );
  const [desiredZoom] = useState(18);

  const [snackbarStatus, setSnackbarStatus] = useState(true);
  const hoverOnFeature = useRef(false);

  const [dragInProgress, setDragInProgress] = useState<{ status: boolean; indexOfCoords?: number }>({ status: false });
  const dragInProgressRef = useRef<{ status: boolean; indexOfCoords?: number }>({ status: false });

  useEffect(() => {
    //Listener for mouseup event if onDragEnd fails
    const listener = () => {
      if (dragInProgressRef.current.status) {
        if (mapRef.current) {
          mapRef.current.getCanvas().style.cursor = 'grab';
        }
        dragInProgressRef.current = { status: false };
        setDragInProgress({ status: false });
      }
    };
    window.addEventListener('mouseup', listener);
    return () => {
      window.removeEventListener('mouseup', listener);
    };
  }, []);

  const onMouseMove = useCallback(
    (evt: MapboxGl.EventData) => {
      if (dragInProgressRef.current.status === true && dragInProgressRef.current.indexOfCoords !== undefined) {
        const index = dragInProgressRef.current.indexOfCoords;
        const { lat, lng } = evt.lngLat;

        setCapturedLocation((prevState: GeoJSON.Point | GeoJSON.Point[] | null | undefined) => {
          if (!prevState) {
            return prevState;
          } else if (prevState && !Array.isArray(prevState)) {
            return [
              {
                type: prevState.type,
                coordinates: [lng, lat],
              },
            ];
          } else if (prevState && Array.isArray(prevState)) {
            let temp = [...prevState];
            temp[index] = {
              type: 'Point',
              coordinates: [lng, lat],
            };
            return [...temp];
          }
          return null;
        });
      }
      return;
    },
    [capturedLocation]
  );

  useEffect(() => {
    if (Array.isArray(capturedLocation)) {
      if ((capturedLocation || []).length && snackbarStatus) {
        setSnackbarStatus(false);
      }
    }
  }, [capturedLocation]);

  const mapItems: Array<MapItem> = assetData
    .filter(
      (asset: any) =>
        asset.id &&
        asset.primaryLocation?.type &&
        asset.primaryLocation?.coordinates &&
        (!asset.recordTypeKey || visibleRecordKeys.includes(asset.recordTypeKey))
    )
    .map(asset => {
      return {
        location: asset.primaryLocation,
        styleKey: asset.symbolKey,
        id: asset.id,
        selected: false,
        aggregateType: asset.recordTypeKey,
      };
    });
  const [mapViewBox, setMapViewBox] = useState<MapFeature<Polygon, Properties>>();
  const mapRef = useRef<MapboxGl.Map | null>(null);
  const locationStyles = useLocationStyles();
  const currentLocation = useCurrentLocation();

  const onMapLoad: MapEvent = map => {
    mapRef.current = map;
    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = 'default';
      if (capturedLocation && Array.isArray(capturedLocation) && capturedLocation.length > 1) {
        setTimeout(() => mapRef.current?.fitBounds(getFitBounds(capturedLocation.map(x => x.coordinates)), { animate: false }), 200);
      }
    }
    mapRef.current?.on('mousemove', onMouseMove);
  };

  const onClick = (map: MapboxGl.Map, evt: MapboxGl.EventData) => {
    setManualMode(false);
    if (selectedPoint?.coordinates || selectedPointRef.current !== undefined) {
      selectedPointRef.current = undefined;
      setSelectedPoint(undefined);
      return;
    }
    const { lat, lng } = evt.lngLat;
    setIsValidLocation(true);
    if (hoverOnFeature.current === false) {
      setCapturedLocation((prevState: GeoJSON.Point | GeoJSON.Point[] | null | undefined) => {
        const canAddLocation = prevState
          ? (prevState && Array.isArray(prevState) && maximum && prevState.length < maximum) || unlimited
          : true;
        if (!canAddLocation) {
          setOpenAddLocationAlert(true);
          return prevState;
        }
        if (!prevState) {
          const newState: GeoJSON.Point[] = [
            {
              type: 'Point',
              coordinates: [lng, lat],
            },
          ];
          return newState;
        } else if (prevState && !Array.isArray(prevState)) {
          return [
            {
              type: prevState.type,
              coordinates: prevState.coordinates,
            },
            {
              type: 'Point',
              coordinates: [lng, lat],
            },
          ];
        } else if (prevState && Array.isArray(prevState)) {
          return [
            ...prevState,
            {
              type: 'Point',
              coordinates: [lng, lat],
            },
          ];
        }
        return null;
      });
    }
  };

  const flyTo = (center: LatLon) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [center?.longitude, center?.latitude],
      });
    }
  };

  const captureDeviceLocation = () => {
    setManualMode(false);
    if (currentLocation) {
      flyTo(currentLocation);
      const { latitude, longitude } = currentLocation;
      setCapturedLocation(prev => {
        const newLocation: Point = {
          type: 'Point',
          coordinates: [longitude, latitude],
        };
        const locationLengthExceeded = Array.isArray(prev) && maximum !== undefined && prev.length >= maximum;
        if (locationLengthExceeded) {
          setOpenAddLocationAlert(true);
          return prev;
        }
        if (Array.isArray(prev)) {
          return [...prev, newLocation];
        } else {
          return [newLocation];
        }
      });
    }
  };

  const geoJsonToCoordinates = useCallback((geoJson: any): [number, number] => {
    if (geoJson.type === 'Point') {
      if (
        geoJson.coordinates &&
        typeof geoJson.coordinates[0] === 'number' &&
        typeof geoJson.coordinates[1] === 'number'
      )
        return [geoJson.coordinates[0], geoJson.coordinates[1]];
    }
    if (geoJson && typeof geoJson.lat === 'number' && typeof geoJson.lon === 'number') {
      return [geoJson.lon, geoJson.lat];
    }
    return [parseFloat(initialMapExtents.lon) || 0, parseFloat(initialMapExtents.lat) || 0];
  }, []);

  const getCenter = useCallback((): [number, number] | undefined => {
    if (!!capturedLocation) {
      return geoJsonToCoordinates(Array.isArray(capturedLocation) ? capturedLocation[0] : capturedLocation);
    }
    return undefined;
  }, [capturedLocation]);

  const desiredCenter = useMemo(() => getCenter(), []);

  const capturedLocationGeoJSON = useMemo(() => {
    const featuresOfLocations = (Array.isArray(capturedLocation) && capturedLocation ? capturedLocation : []).map(
      (location: GeoJSON.Point, index) => {
        if (location?.coordinates && isCoordinatesValid(location?.coordinates[1], location?.coordinates[0])) {
          return point(location.coordinates, { index: index + 1 });
        }
      }
    );
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: featuresOfLocations,
      },
    };
  }, [capturedLocation, dragInProgress.status]);

  const [popUpObj, setPopUpObj] = useState<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);

  const mouseEnterFeature = useCallback(
    (e: any, index: number, selectedPoint?: boolean) => {
      if (dragInProgressRef.current.status === false) {
        hoverOnFeature.current = true;
        if (selectedPoint) {
          return;
        }
        if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'grab';
        if (Array.isArray(capturedLocation) && capturedLocation[index]) {
          setPopUpObj({ coordinates: capturedLocation[index]?.coordinates, indexOfCoords: index });
        }
      }
    },
    [selectedPoint, capturedLocation]
  );

  const mouseLeaveFeature = useCallback((e: any, index: number) => {
    if (dragInProgressRef.current.status === false) {
      hoverOnFeature.current = false;
      if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'default';
      setPopUpObj(undefined);
    }
  }, []);

  const dragStart = useCallback(
    (index: number) => (evt: any) => {
      setPopUpObj(undefined);
      setDragInProgress({ status: true, indexOfCoords: index });
      dragInProgressRef.current = { status: true, indexOfCoords: index };
      setSelectedPoint(undefined);
      selectedPointRef.current = undefined;
      if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'grabbing';
    },
    []
  );

  const dragEnd = useCallback(
    (index: number) => (evt: any) => {
      setDragInProgress({ status: false });
      dragInProgressRef.current = { status: false };
      if (mapRef.current) {
        mapRef.current.getCanvas().style.cursor = 'grab';
      }
    },
    []
  );

  const selectedPointPaint = {
    'circle-color': `${theme.palette.primary.main || colors.defaultPrimary}`,
    'circle-radius': 16,
    'circle-stroke-color': `${theme.palette.secondary.main || colors.white}`,
    'circle-stroke-width': 3,
  };
  const pointPaint = {
    'circle-color': `${theme.palette.primary.main || colors.defaultPrimary}`,
    'circle-radius': 12,
    'circle-stroke-color': `${theme.palette.secondary.main || colors.white}`,
    'circle-stroke-width': 1,
  };

  const featuresToRender = useMemo(() => {
    return (capturedLocationGeoJSON?.data?.features || []).map((feature, index) => {
      if (index === selectedPointRef.current?.indexOfCoords) return <></>;
      const dragStatus =
        dragInProgressRef.current.status === false || dragInProgressRef?.current.indexOfCoords === index ? true : false;

      return (
        <Feature
          coordinates={feature?.geometry?.coordinates || [0, 0]}
          key={index}
          draggable={dragStatus}
          onDragStart={dragStart(index)}
          onDragEnd={dragEnd(index)}
          onMouseEnter={(e: any) => mouseEnterFeature(e, index)}
          onMouseLeave={(e: any) => mouseLeaveFeature(e, index)}
          onClick={() => {
            if (selectedPoint === undefined) {
              selectedPointRef.current = {
                indexOfCoords: index,
                coordinates: feature?.geometry?.coordinates || [0, 0],
              };
              setSelectedPoint({ indexOfCoords: index, coordinates: feature?.geometry?.coordinates || [0, 0] });
            }
          }}
        />
      );
    });
  }, [capturedLocationGeoJSON?.data?.features, capturedLocation, dragInProgress?.status]);

  const MapRef = useRef<HTMLDivElement | null>(null);
  return (
    <div style={{ height: '100%' }} ref={MapRef}>
      {!isMobileView && (
        <HideNonSelectableButtons
          buttonCaption="Other Records"
          paddingRight={13}
          paddingBottom={34}
          {...{ handleHidingNonSelectableRecords, hiding }}
        />
      )}
      <Box className={`${locationStyles.fullHeightMapContainer}`}>
        {isMobileView && (
          <HideNonSelectableButtons
            buttonCaption="Other Records"
            paddingRight={13}
            paddingBottom={12}
            {...{ handleHidingNonSelectableRecords, hiding }}
          />
        )}
        <Map
          desiredZoom={desiredZoom}
          desiredCenter={desiredCenter}
          items={mapItems}
          onStyleLoad={onMapLoad}
          onClick={onClick}
          setMapViewBox={setMapViewBox}
          mapViewBox={mapViewBox}
          height={MapRef.current?.clientHeight}
          width={MapRef.current?.clientWidth}
          setMapBounds={setMapBounds}
          currentZoomLevel={currentZoomLevel}
          setCurrentZoomLevel={setCurrentZoomLevel}
        >
          <Source id="righthere" geoJsonSource={capturedLocationGeoJSON} />
          <Layer paint={selectedPointPaint} type="circle" id="uniquelayer4">
            {selectedPoint &&
              selectedPoint?.coordinates &&
              (selectedPoint?.indexOfCoords || selectedPoint?.indexOfCoords === 0) && (
                <Feature
                  coordinates={selectedPoint?.coordinates}
                  key={selectedPoint?.indexOfCoords}
                  draggable={false}
                  onDragStart={dragStart(selectedPoint?.indexOfCoords)}
                  onMouseEnter={(e: any) => mouseEnterFeature(e, selectedPoint?.indexOfCoords, true)}
                  onMouseLeave={(e: any) => mouseLeaveFeature(e, selectedPoint?.indexOfCoords)}
                  onClick={() => {
                    selectedPointRef.current = undefined;
                    setSelectedPoint(undefined);
                  }}
                />
              )}
          </Layer>
          {popUpObj && (
            // @ts-ignore
            <Popup
              coordinates={popUpObj.coordinates}
              key={-2}
              anchor="bottom-left"
              offset={[12, -12]}
              className={classes.popupWrapper}
              style={{ maxHeight: 30, maxWidth: 130, fontFamily: 'Roboto', background: colors.lightGoldenrodYellow }}
            >
              <p
                style={{ fontFamily: 'Roboto', background: colors.lightGoldenrodYellow }}
                className={classes.popupItem}
              >
                Drag the pin to change the location
              </p>
            </Popup>
          )}
          <Layer
            type="symbol"
            sourceId="righthere"
            id="uniquelayer2"
            paint={{ 'text-color': colors.white }}
            layout={{
              'text-field': ['get', 'index'],
              'text-letter-spacing': 0.1,
              'text-size': 14,
              'text-offset': [0, 0],
              'text-justify': 'center',
              'text-font': ['Arial Unicode MS Bold'],
            }}
          />
          <Layer type="circle" before="uniquelayer4" id="uniquelayer" paint={pointPaint} layout={{}}>
            {featuresToRender}
          </Layer>
        </Map>
        <MapButtons
          {...{ snackbarStatus, setSnackbarStatus, captureDeviceLocation, message: 'Click on map to add location pin' }}
        />
      </Box>
    </div>
  );
};
