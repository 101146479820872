import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { colors } from '../../../styles/theme';
import { Dispatch, SetStateAction } from 'react';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';

const FOOTER_HEIGHT = 76;
const BOTTOMBAR_HEIGHT = 67;
export interface BaseLocationMapProps {
  setIsValidLocation: Dispatch<SetStateAction<boolean>>;
  items?: any;
  onStyleLoad?: any;
}
export const useLocationStyles = makeStyles((theme: Theme) =>
  createStyles({
    mapContainer: {
      position: 'relative',
      maxWidth: '100%',
      overflow: 'hidden',
      height: '50vh',
    },
    fullHeightMapContainer: {
      position: 'relative',
      maxWidth: '100%',
      overflow: 'hidden',
      height: '100%',
    },
    formPointMapContainer: {
      height: '66vh',
    },
    formPolygonMapContainer: {
      height: '66vh',
    },
    formPolygonMapContainerWeb: {
      height: '100%',
    },
    mapText: {
      background: colors.white95,
      width: 440,
      padding: '14px 18px',
      display: 'flex',
      gap: 10,
      borderRadius: 4,
      margin: 'auto auto 30px auto',
      boxShadow: ` 0px 2px 4px 0px ${colors.black10}`,
      zIndex: 101,
    },
    text: { fontSize: 14, fontWeight: 400, color: colors.black0 },
    currentLocation: { display: 'flex' },
    captureLocationBtn: {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
      padding: '8px 18px',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '26px',
      letterSpacing: 0.46,
      borderRadius: 3,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: colors.white,
      },
    },
    centerModal: {
      position: 'absolute',
      bottom: 25,
      background: colors.white95,
      width: 440,
      padding: '14px 18px',
      display: 'flex',
      gap: 10,
      borderRadius: 4,
      boxShadow: ` 0px 2px 4px 0px ${colors.black10}`,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        background: colors.white95,
        width: 330,
        padding: '14px 18px',
        display: 'flex',
        gap: 10,
        zIndex: 100,
        top: 19,
        bottom: 'auto',
      },
    },
    captureLocationBtnDis: {
      backgroundColor: colors.pastalGray,
      color: colors.philpphineSilver,
    },
    bottomCenterBtn: {
      position: 'absolute',
      bottom: 27,
      zIndex: 999,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        bottom: 'auto',
        top: 19,
      },
    },
    bottomRightBtn: {
      position: 'absolute',
      bottom: 82,
      right: 13,
      zIndex: 2,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        whiteSpace: 'nowrap',
        bottom: 58,
      },
    },
    botRightMob: {
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        position: 'relative !important',
        float: 'right',
        bottom: 69,
        right: '27px !important',
      },
    },
    closeBtn: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: 'normal',
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        fontSize: 13,
        padding: 0,
      },
    },
    bottomTopRightBtn: {
      position: 'absolute',
      bottom: 86,
      right: 13,
      zIndex: 2,
      height: 42,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        bottom: 96,
        height: 38,
        position: 'relative',
        float: 'right',
      },
    },
    captureLocationIcon: {
      color: colors.royalBlue,
    },
  })
);
