import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAggregates } from '../../../contexts/AggregatesContext';
import { gql, useApolloClient } from '@apollo/client';
import _ from 'lodash';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import { getQuery } from '../../../contexts/AggregatesContext/useAssetsLoader';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useSelectedProject } from '../../../contexts/selectedProjectContext';
import { useRecoilValue } from 'recoil';
import { mapSelectBoundsState } from '../../../recoil/atoms/mapMaintenance';
import { v4 } from 'uuid';

const useHideNonSelectable = () => {
  const assetRecord = useAggregates();
  const [assetData, setAssetData] = useState<MapAssetType[]>([]);
  const [defaultTypes] = useState(assetRecord.visibleAggregateTypesNames);
  const [hiding, setHiding] = useState(false);
  const client = useApolloClient();
  const { selectedProjects, getAllProjectIds } = useSelectedProject();
  const { aggregateDefinitions } = useConfig();
  const mapBounds = useRecoilValue(mapSelectBoundsState);
  const [visibleAggregateTypesNames, setVisibleAggregateTypesNames] = useState<(string)[]>([...defaultTypes]);
  const currentLoad = useRef<number>(0);
  const prevFetchInfo = useRef<{
    minLat?: number;
    minLon?: number;
    maxLat?: any;
    maxLon?: any;
    aggregates?: string;
    hasClusters?: boolean;
  }>({});

  const allProjectIds = useMemo(() => getAllProjectIds(), [getAllProjectIds]);

  const updateBoundedAssets = useCallback(
    (loadCount: number, fetchedAssets: MapAssetType[], bounds: {
      minLat: number;
      minLon: number;
      maxLat: number;
      maxLon: number;
      aggregates: string;
  }) => {
      if (loadCount === currentLoad.current) {
        prevFetchInfo.current = {...bounds,hasClusters:fetchedAssets.some(a=>a.id?.match(/^Cluster-/))};
        setAssetData(fetchedAssets);
      }
    },
    [setAssetData]
  );

  const boundsQueryController = useRef<AbortController>();

  const abortPreviousBoundsQuery = useCallback(() => {
    boundsQueryController.current?.abort();
    const newController = new AbortController();
    boundsQueryController.current = newController;
    return newController;
  }, []);

  const mapInstanceUuid = useMemo(() => v4(), []); // Unique identifier for this map instance; used for queries requiring supersedure.

  const debouncedMapReload = useCallback(_.debounce((bounds, visibleAggregateTypesNames)=>{
    const aggregates =aggregateDefinitions.filter(a=>visibleAggregateTypesNames.includes(a.name) && !a.hiddenFromMapView)
      .map(d => getQuery(d,undefined,selectedProjects,undefined,allProjectIds))
      .filter(a => a);
    const latSplit = 5;
    const lonSplit = 5;
    const prevData = prevFetchInfo.current;
    if (bounds && (JSON.stringify(aggregates) !== prevData.aggregates || 
                    bounds.minLat < prevData.minLat! ||
                    bounds.minLon < prevData.minLon! ||
                    bounds.maxLat > prevData.maxLat! ||
                    bounds.maxLon > prevData.maxLon! ||
                    (prevData.hasClusters &&
                      (
                        (bounds.maxLat - bounds.minLat)/(prevData.maxLat! - prevData.minLat!)<.9 ||
                        (bounds.maxLon - bounds.minLon)/(prevData.maxLon! - prevData.minLon!)<.9
                      )
                    )
                  )) {
      const latRange = bounds.maxLat - bounds.minLat;
      const lonRange = bounds.maxLon - bounds.minLon;

      const expandedBounds = {
        minLat: bounds.minLat - latRange/latSplit,
        minLon: bounds.minLon - lonRange/lonSplit,
        maxLat: bounds.maxLat + latRange/latSplit,
        maxLon: bounds.maxLon + lonRange/lonSplit
      }
      const thisLoad = ++currentLoad.current;
      const newAbortController = abortPreviousBoundsQuery();
      client
        .query<{ aggregateBounds: MapAssetType[] }>({
          query: gql`
            query aggregateBounds(
              $queryDef: JSON!
              $minLon: Float!
              $maxLon: Float!
              $minLat: Float!
              $maxLat: Float!
              $latSplit: Float
              $lonSplit: Float
              $supersedureId: String!
              $supersedureIteration: Int!
            ) {
              aggregateBounds(
                filter: {
                  minLat: $minLat
                  minLon: $minLon
                  maxLat: $maxLat
                  maxLon: $maxLon
                  queryDef: $queryDef
                  latSplit: $latSplit
                  lonSplit: $lonSplit
                },
                supersedureOptions: {
                  supersedureId: $supersedureId
                  iteration: $supersedureIteration
                }
              )
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            ...expandedBounds,
            queryDef: aggregates,
            latSplit: latSplit+2,
            lonSplit: lonSplit+2,
            supersedureId: mapInstanceUuid,
            supersedureIteration: thisLoad,
          },
          context: {
            fetchOptions: {
              signal: newAbortController.signal, // https://stackoverflow.com/questions/78139168/aborting-long-running-apollo-client-queries-in-react-component
            },
          },
        })
        .then(result => {
          updateBoundedAssets(thisLoad, result.data.aggregateBounds, {...expandedBounds,aggregates: JSON.stringify(aggregates)})
        })
        .catch(e => {
          console.log('fetch error', e);
        });
    }
  },500),[])

  useEffect(() => {
    debouncedMapReload(mapBounds, visibleAggregateTypesNames)
  }, [mapBounds, visibleAggregateTypesNames]);

  const handleHidingNonSelectableRecords = (selectableAggregateTypes: string[] = []) => {
    if (!!hiding) {
      // Make all types visable
      setVisibleAggregateTypesNames([...defaultTypes]);
      setHiding(false);
    } else {
      //  hide non clickable
      selectableAggregateTypes &&
        setVisibleAggregateTypesNames([...selectableAggregateTypes.filter(type => defaultTypes.includes(type))]);
      setHiding(true);
    }
  };

  return { handleHidingNonSelectableRecords, assetData, visibleAggregateTypesNames, hiding };
};

export default useHideNonSelectable;
