import { atom } from 'recoil';

export const mapBoundsState = atom<{ minLat: number; minLon: number; maxLat: number; maxLon: number } | null>({
  key: 'mapBoundsState',
  default: null,
});

export const mapSelectBoundsState = atom<{ minLat: number; minLon: number; maxLat: number; maxLon: number } | null>({
  key: 'mapSelectBoundsState',
  default: null,
});

export const forceMapReloadState = atom<boolean>({
  key: 'forceMapReloadState',
  default: false,
});

export const cardIdsState = atom<string[]>({
  key: 'cardIdsState',
  default: [],
});

export const selectedItemsState = atom<any[]>({
  key: 'selectedItemsState',
  default: [],
});
