import distance from '@turf/distance';
import { round, getUnitAbbreviation, WebUIConfig } from '@terragotech/gen5-shared-components';
import { LineString, Units } from '@turf/helpers';
import _ from 'lodash';
import { LngLatBoundsLike } from 'mapbox-gl';
import { Position } from 'geojson';

export function calculateMeasurement(
  lineString: LineString | null | undefined,
  geographic?: WebUIConfig['geographic']
) {
  const defaultConfig = {
    polylineUnitOfMeasurement: 'meters' as Units,
    polylineRoundingPrecision: 'tenths' as 'ones' | 'tenths' | 'hundredths' | 'thousandths',
  };

  const config = geographic || defaultConfig;

  const polylineUnitOfMeasurement = config.polylineUnitOfMeasurement;
  const polylineRoundingPrecision = config.polylineRoundingPrecision;

  if (!lineString || !lineString.coordinates.length) {
    return {
      segments: 0,
      measurement: 0,
      configUnits: polylineUnitOfMeasurement,
      unitsAbbreviation: getUnitAbbreviation(polylineUnitOfMeasurement as Units),
    };
  }

  const configRoundingPrecision: 'ones' | 'tenths' | 'hundredths' | 'thousandths' =
    polylineRoundingPrecision || 'tenths';

  const configUnits: Units | undefined = polylineUnitOfMeasurement ?? defaultConfig.polylineUnitOfMeasurement;

  let previousLocation: number[] | undefined;
  let totalDistance = 0;

  lineString.coordinates.forEach((location: number[]) => {
    if (previousLocation) {
      const distanceInUnits = distance(location, previousLocation, { units: configUnits });
      totalDistance += distanceInUnits;
    }
    previousLocation = location;
  });

  const total = round(totalDistance, configRoundingPrecision);
  const segments = lineString.coordinates.length;
  const unitsAbbreviation = getUnitAbbreviation(configUnits as Units);

  return {
    segments,
    measurement: total,
    configUnits,
    unitsAbbreviation,
  };
}

/**
 * @param coordinates The set of planar coordinates for which to find the minimum bounding box.
 * @param differentialFactor The factor for which to apply extra space surrounding the bounding box.
 * @returns The minimum buffered bounding box which fits all specified planar coordinates.
 */
export const getFitBounds = (coordinates: Position[], differentialFactor: number = 0.1): LngLatBoundsLike => {
  const minLon = Math.min(...(coordinates.map(coord => coord[0]) ?? []))
  const minLat = Math.min(...(coordinates.map(coord => coord[1]) ?? []))
  const maxLon = Math.max(...(coordinates.map(coord => coord[0]) ?? []))
  const maxLat = Math.max(...(coordinates.map(coord => coord[1]) ?? []))
  const dLon = (maxLon - minLon) * differentialFactor;
  const dLat = (maxLat - minLat) * differentialFactor;
  return [
    [minLon - dLon, minLat - dLat],
    [maxLon + dLon, maxLat + dLat],
  ];
};
