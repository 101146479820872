import { Box, Button, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Polygon, Properties } from '@turf/helpers';
import { Feature as MapFeature } from 'geojson';
import * as MapboxGl from 'mapbox-gl';
import { MapLayerEventType } from 'mapbox-gl';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef, useState, useContext } from 'react';
import { Marker } from 'react-mapbox-gl';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { LatLon, useConfig, useCurrentLocation, MapBoundsContext } from '@terragotech/gen5-shared-components';
import Map, { MapItem } from '../../Map/component/Map';
import { BaseLocationMapProps, useLocationStyles } from './CommonEditorUtils';
import { AssetsDashboardContext } from '../../../contexts/assetsDashboardContext';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
interface TGPointEditorProps extends BaseLocationMapProps {
  capturedLocation: GeoJSON.Point | null | undefined;
  setCapturedLocation: Dispatch<SetStateAction<GeoJSON.Point | null | undefined>>;
  setManualMode: Dispatch<SetStateAction<boolean>>;
  mapContainerStyle?: string;
  children?: React.ReactElement;
  visibleAggregateTypesNames: string[];
  assetData: MapAssetType[];
  setMapBounds: any
}

export const TGPointEditor: React.FC<TGPointEditorProps> = props => {
  const {
    setCapturedLocation,
    capturedLocation,
    setManualMode,
    mapContainerStyle,
    children,
    setIsValidLocation,
    assetData,
    visibleAggregateTypesNames,
  } = props;

  // get all assets to display based on filters
  const configContext = useConfig();
  const { aggregateDefinitions } = useConfig();
  const { mapCenter, currentZoomLevel: contextCurrentZoomLevel } = useContext(MapBoundsContext);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(contextCurrentZoomLevel);
  const { isMobileView } = useContext(AssetsDashboardContext);
  const [close, setClose] = useState<boolean>(true);
  const handleClose = () => {
    setClose(false);
  };
  const visibleRecordKeys = useMemo(
    () => visibleAggregateTypesNames.map(name => aggregateDefinitions.find(agg => agg.name === name)?.queryKey),
    [visibleAggregateTypesNames, aggregateDefinitions]
  );
  const [desiredZoom, setDesiredZoom] = useState(18);

  const mapItems: Array<MapItem> = assetData
    .filter(
      (asset: any) =>
        asset.id &&
        asset.primaryLocation?.type &&
        asset.primaryLocation?.coordinates &&
        (!asset.recordTypeKey || visibleRecordKeys.includes(asset.recordTypeKey))
    )
    .map(asset => {
      return {
        location: asset.primaryLocation,
        styleKey: asset.symbolKey,
        id: asset.id,
        selected: false,
        aggregateType: asset.recordTypeKey,
      };
    });
  const [mapViewBox, setMapViewBox] = useState<MapFeature<Polygon, Properties>>();
  const mapRef = useRef<MapboxGl.Map | null>(null);
  const locationStyles = useLocationStyles();
  const currentLocation = useCurrentLocation();

  const onMapLoad: MapEvent = map => {
    mapRef.current = map;
  };

  //Various event handlers
  const onSymbolClick = useCallback((e: MapLayerEventType['click' | 'touchend' | 'touchstart']) => {
    const feature = e.features?.[0];
    // select item, highlight, set once listener?
    if (feature) {
      // do something
    }
  }, []);

  // add to Map API?
  const onClick = (map: MapboxGl.Map, evt: MapboxGl.EventData) => {
    setManualMode(false);
    const { lat, lng } = evt.lngLat;
    setCapturedLocation({
      type: 'Point',
      coordinates: [lng, lat],
    });
    setIsValidLocation(true);
  };

  const flyTo = (center: LatLon) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [center?.longitude, center?.latitude],
      });
    }
  };

  const captureDeviceLocation = () => {
    setManualMode(false);
    if (currentLocation) {
      flyTo(currentLocation);
      const { latitude, longitude } = currentLocation;
      setCapturedLocation({
        type: 'Point',
        coordinates: [longitude, latitude],
      });
      //    setIsValidLocation(true);
    }
  };

  const geoJsonToCoordinates = useCallback((geoJson: any): [number, number] => {
    if (geoJson.type === 'Point') {
      if (
        geoJson.coordinates &&
        typeof geoJson.coordinates[0] === 'number' &&
        typeof geoJson.coordinates[1] === 'number'
      )
        return [geoJson.coordinates[0], geoJson.coordinates[1]];
    }
    if (geoJson && typeof geoJson.lat === 'number' && typeof geoJson.lon === 'number') {
      return [geoJson.lon, geoJson.lat];
    }
    return [parseFloat(configContext.initialMapExtents.lon) || 0, parseFloat(configContext.initialMapExtents.lat) || 0];
  }, []);

  //Determine Center Captured Location -> Default Config Lat Lon
  const getCenter = useCallback((): [number, number] | undefined => {
    if (!!capturedLocation) {
      return geoJsonToCoordinates(capturedLocation);
    }
    return undefined;
  }, [capturedLocation]);

  const desiredCenter = useMemo(() => getCenter(), []);

  return (
    <Box className={mapContainerStyle}>
      <Map
        desiredZoom={desiredZoom}
        desiredCenter={desiredCenter}
        items={mapItems}
        onStyleLoad={onMapLoad}
        onSymbolClick={onSymbolClick}
        onClick={onClick}
        setMapViewBox={setMapViewBox}
        mapViewBox={mapViewBox}
        setMapBounds={props.setMapBounds}
        currentZoomLevel={currentZoomLevel}
        setCurrentZoomLevel={setCurrentZoomLevel}
      >
        {!!capturedLocation &&
          capturedLocation?.coordinates &&
          capturedLocation.coordinates[0] &&
          capturedLocation.coordinates[1] && (
            <Marker coordinates={[capturedLocation?.coordinates[0], capturedLocation?.coordinates[1]]}>
              <LocationOnIcon className={locationStyles.captureLocationIcon} />
            </Marker>
          )}
      </Map>
      {close && !isMobileView && (
        <Box className={locationStyles.mapText}>
          <Typography className={locationStyles.text}>
            To enter a location you can click on the map, capture your devices location, or enter it manually.
          </Typography>
          <Button variant="text" className={locationStyles.closeBtn} onClick={handleClose}>
            Close
          </Button>
        </Box>
      )}
      {currentLocation ? (
        <>
          <div className={isMobileView ? locationStyles.currentLocation : ''}>
            <Button
              className={`${locationStyles.captureLocationBtn} ${locationStyles.bottomRightBtn}`}
              variant="contained"
              onClick={captureDeviceLocation}
            >
              capture device location
            </Button>
            {children}
          </div>
          {close && isMobileView && (
            <Box className={locationStyles.centerModal}>
              <Typography className={locationStyles.text}>
                To enter a location you can click on the map, capture your devices location, or enter it manually.
              </Typography>
              <Button variant="text" className={locationStyles.closeBtn} onClick={handleClose}>
                Close
              </Button>
            </Box>
          )}
        </>
      ) : (
        <Tooltip
          className={`${locationStyles.captureLocationBtnDis} ${locationStyles.bottomRightBtn}`}
          title="This device does not have a location"
          placement="top"
        >
          <Button
            color="default"
            variant="contained"
            className={`${locationStyles.captureLocationBtn} ${locationStyles.bottomRightBtn}`}
          >
            capture device location
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};
