import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { SortDirection } from '@terragotech/react-data-grid';

const useSort = (
  defaultKey?: string,
) => {
  const [[sortKey, sortDirection], setSort] = useState<[string, SortDirection]>([defaultKey || '', 'ASC']);

  const handleSort = useCallback(
    (newVal: {
      sortKey: string,
      sortDirection: SortDirection,
    }) => {
      setSort([newVal.sortKey, newVal.sortDirection]);
    },
    []
  );

  return { sortKey, sortDirection, handleSort };
};

export default useSort;
