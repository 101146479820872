import React, { createContext, FunctionComponent, useState, useEffect, useContext } from 'react';
import { useConfig, AggregateDefinition } from '@terragotech/gen5-shared-components';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { AssetsDashboardContext } from './assetsDashboardContext';
import { useSetRecoilState } from 'recoil';
import { selectedRecordTypeState } from '../recoil/atoms';

interface RecordTypeContextType {
  selectedRecordTypeDefinition: AggregateDefinition;
  selectedRecordType: string;
  setSelectedRecordType: (newType: AggregateDefinition) => void;
}

export const RecordTypeContext = createContext<RecordTypeContextType>({
  setSelectedRecordType: () => null,
  selectedRecordType: '',
  selectedRecordTypeDefinition: {
    name: 'dummy',
    propertyDefinitions: [],
    plural: 'dummies',
    queryKey: 'dummy',
    singular: 'dummy',
    cardDefinition: {
      showTitleLabel: true,
      template: 'basicCard',
      titleKey: '',
      otherAttributes: [],
      secondaryButtons: [],
    },
  },
});
export const RecordTypeProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = props => {
  const configContext = useConfig();
  const history = useHistory();
  const match = useRouteMatch<{ recordType: string }>('/:recordType/');
  const [selectedRecordTypeDefinition, setSelectedRecordType] = useState<AggregateDefinition | null>(null);
  const setSelectedType = useSetRecoilState(selectedRecordTypeState);
  const { rowIdxRef } = useContext(AssetsDashboardContext);

  const setRecordType = (type: AggregateDefinition) => {
    setSelectedRecordType(type);
    setSelectedType(type.name);
    //make sure url matches with current record type
    if (!match || (match && type.name !== match.params.recordType)) {
      history.push(`/${type.name}`);
    }
    rowIdxRef.current = 0;
  };

  useEffect(() => {
    // Set selected record type based on record type in current URL.
    const recordTypeFromUrl = match?.params.recordType;
    const newDef = configContext.aggregateDefinitions.find(d => d.name === recordTypeFromUrl);
    const newType = newDef || configContext.aggregateDefinitions.filter(def => def.name !== 'Project' && def.name !== 'Folder')[0];
    setSelectedType((prev)=>prev === newType?.name ? prev : newType?.name || '');
    setSelectedRecordType(newType);

    // because of exhaustive deps, this will get called twice, but nothing should change, so we should be ok.
  }, [configContext.aggregateDefinitions, setSelectedRecordType, match]);

  return (
    selectedRecordTypeDefinition && (
      <RecordTypeContext.Provider
        value={{
          selectedRecordType: selectedRecordTypeDefinition?.name || '',
          selectedRecordTypeDefinition,
          setSelectedRecordType: setRecordType,
        }}
      >
        {props.children}
      </RecordTypeContext.Provider>
    )
  );
};
export const useRecordType = () => useContext(RecordTypeContext);
