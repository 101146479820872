/* eslint-disable react-hooks/exhaustive-deps */
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import {
  AggregateDefinition,
  MapBoundsContext,
  trimTrailingSlash,
  useConfig,
} from '@terragotech/gen5-shared-components';
import {
  DATETIME_TOKEN_CONVERSION,
  getDateFormat,
  getDateTimeFormat,
  MAP_SERVICE_TYPE,
} from '@terragotech/gen5-shared-utilities';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import distance from '@turf/distance';
import { Polygon, Properties } from '@turf/helpers';
import { Feature, Position } from 'geojson';
import { History } from 'history';
import * as MapboxGl from 'mapbox-gl';
import { GeolocateControl, MapLayerEventType } from 'mapbox-gl';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Symbol } from '../components/Legend';
import AssetCardRenderer, { AssetCardRendererRef } from '../components/Map/AssetCard/AssetCardRenderer';
import Map, { MaxZoomClickHandler, MAX_ZOOM } from '../components/Map/component/Map';
import { useAggregates } from '../contexts/AggregatesContext';
import { MapAssetType } from '../contexts/AggregatesContext/types';
import { useAssetCards } from '../contexts/assetCardContext';
import { useRecordType } from '../contexts/recordTypeContext';
import { ValueType } from '../hooks/useTable';
import { mapLabelSelector } from '../recoil/atoms';
import { arcGisParams, rasterSourceData, wmsParams } from '../utils/mapInfo';
import { CircularProgress } from '@material-ui/core';
import { useSelectedLocation } from '../contexts/selectedLocationContext';
import { colors } from '../styles/theme';
import { useTableColumns } from '../contexts/TableColumnContext';
import useRouteParams from '../components/Common/useRouteParams';

export interface MapItemsContainerProps extends WithStyles<typeof styles> {
  assets: MapAssetType[];
  lat?: any;
  lon?: any;
  zoom?: any;
  onFormSubmit: () => void;
  setItems?: (items:any) => void;
  symbols?: Symbol[];
  includeWMS?: boolean;
  setMapBounds: React.Dispatch<React.SetStateAction<any>>;
  showLoading?: boolean;
  showLocationSearch?: boolean;
  hideVisibility?: boolean;
  mapParams?: any;
}

/**
 * @remarks Obtains variables for props, returns Map with props
 *
 * @param props
 */
const MapItemsContainer: React.FunctionComponent<MapItemsContainerProps> = props => {
  const { selectedRecordType } = useRecordType();
  const mapLabelVisibility = useRecoilValue(mapLabelSelector);

  const { setAssetsIds, setCoordinates, assetIds } = useAssetCards();
  const history: History = useHistory();
  const [mapInstance, setMapInstance] = useState<MapboxGl.Map | null>(null);
  const [mapViewBox, setMapViewBox] = useState<Feature<Polygon, Properties>>();
  const [items, setItems] = useState<MapAssetType[]>();
  const [aggId, setAggId] = useState<string>();
  const [mapRasterSources, setMapRasterSources] = useState<Record<string, MapboxGl.RasterSource>>();
  const { mapServiceLayers, visibleMapServiceKeys } = useAggregates();
  const { currentZoomLevel, setCurrentZoomLevel, setMapCenter } = useContext(MapBoundsContext);
  const { setSelectedLocation } = useSelectedLocation();
  const assetCardRef = useRef<AssetCardRendererRef>(null);
  const { includeWMS } = props;
  const { onSymbolClick, handleMultipleCardsClose, ...mapParamsRest } = props.mapParams || {};
  const match = useRouteMatch<{ assetId: string }>(`/${selectedRecordType}/:assetId`);
  const assetId = match?.params?.assetId;
  const { setCurrentAssetId } = useTableColumns();
  const { isAssetDetailsOpen } = useRouteParams({
    selectedRecordType,
  });
  useEffect(() => {
    if (!isAssetDetailsOpen) {
      setAggId(assetId);
    }
  }, [assetId]);
  useEffect(() => {
    if (!props.mapParams) {
      setItems((prev)=>{
        const result = props.assets
            .filter(
              (asset: any) =>
              assetIds.includes(asset.id)
            )
        return JSON.stringify(prev) === JSON.stringify(result) ? prev : result
      })
    }
    if(props.setItems && assetIds.length){
      props.setItems((prev:any)=>{
        const result = props.assets
            .filter(
              (asset: any) =>
              assetIds.includes(asset.id)
            )
        return !result.length || JSON.stringify(prev) === JSON.stringify(result) ? prev : result
      })
    }
  }, [assetIds,props.assets]);
  // Adding custom mapbox map control for current location button, because it is not available in our mapbox-react library
  // https://docs.mapbox.com/mapbox-gl-js/api/markers/#geolocatecontrol.event:trackuserlocationstart
  const onMapLoad: MapEvent = useCallback(map => {
    setMapInstance(map);
    map.addControl(
      new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      'top-right'
    );
  },[]);

  const configContext = useConfig();
  const { aggregateDefinitions, defaultDateTimeFormat } = useConfig();
  const [zoom] = useState(Number(props.zoom) || configContext.initialMapExtents.zoom);
  const lat = parseFloat(props.lat || configContext.initialMapExtents.lat) || 0;
  const lon = parseFloat(props.lon || configContext.initialMapExtents.lon) || 0;

  const [center] = useState<[number, number]>([lon, lat]);
  let assetData = props.assets;

  const isDate = (name: ValueType, field: string) => {
    aggregateDefinitions?.map(j => {
      j?.propertyDefinitions?.map(i => {
        if (i.type === 'DateTime' && field === i.field && name) {
          const dateObj = moment(String(name));
          if (dateObj.isValid()) {
            name = dateObj
              ? moment(String(dateObj)).format(
                  getDateTimeFormat(
                    defaultDateTimeFormat?.dateFormatType,
                    defaultDateTimeFormat?.dateFormat,
                    defaultDateTimeFormat?.dateSeperator,
                    defaultDateTimeFormat?.timeFormat,
                    { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                  )
                )
              : '';
          }
        } else if (i.type === 'Date' && field === i.field && name) {
          const dateObj = moment(String(name));
          if (dateObj.isValid()) {
            name = dateObj
              ? moment
                  .utc(String(dateObj))
                  .format(
                    getDateFormat(
                      defaultDateTimeFormat?.dateFormatType,
                      defaultDateTimeFormat?.dateFormat,
                      defaultDateTimeFormat?.dateSeperator
                    )
                  )
              : '';
          }
        }
      });
    });
    return name;
  };

  let getMapLabelDisplay = (asset: MapAssetType, aggregateDefinition: AggregateDefinition | undefined) => {
    let assetMapLabels: Array<{ label: string | undefined; value: ValueType }> = [];
    if (!mapLabelVisibility) {
      return null;
    }
    if (
      !aggregateDefinition ||
      !aggregateDefinition.mapLabelProperties ||
      aggregateDefinition.mapLabelProperties.length < 1
    ) {
      return null;
    }
    (aggregateDefinition?.mapLabelProperties || []).forEach(x => {
      let val = asset[x.name];
      val = isDate(val, x.name);
      const showemptyMapvalues = !!aggregateDefinition?.showEmptyMapLabels;
      // value is empty and showemptylabel is false, don't add to the list
      if ((showemptyMapvalues && !val) || val)
        assetMapLabels.push({
          label: aggregateDefinition?.showMapLabelTitles
            ? aggregateDefinition?.propertyDefinitions.find(t => t.field === x.name)?.label
            : undefined,
          value: val ? val : showemptyMapvalues ? '(empty)' : '',
        });
    });
    return assetMapLabels;
  };

  let getShowMeasurementOnMap = (asset: MapAssetType, aggregateDefinition: AggregateDefinition | undefined) => {
    return (asset.primaryLocation.type !== 'Point' && aggregateDefinition?.showMeasurementOnMap) || false;
  };

  const includeLine = function (location: any) {
    if (location?.type === 'LineString') {
      let dist = 0;
      let previousCoord: Position;
      let minLat = -200,
        minLong = -200,
        maxLat = 200,
        maxLong = 200;
      location.coordinates.forEach((coord: Position) => {
        if (previousCoord) {
          const d = distance(previousCoord, coord, { units: 'meters' });
          dist += d;
          minLat = Math.min(coord[0], minLat);
          maxLat = Math.max(coord[0], maxLat);
          minLong = Math.min(coord[1], minLong);
          maxLong = Math.max(coord[1], maxLong);
        } else {
          minLat = coord[0];
          maxLat = coord[0];
          minLong = coord[1];
          maxLong = coord[1];
        }
        previousCoord = coord;
      });

      const minLinePixelLength = 20;
      const minPixelArea = 5;
      //Calculating approximate pixel length by taking length in meters divided by the meters per pixel based on current zoom
      //This is the most accurate to true number of pixels around 40 latitude, so it should be decent for most of the US.
      //It will underestimate the line length severely if the line is drawn close to the north or south pole and slightly overestimate near the equator
      //Documentation about zoom levels of mapbox can be found at https://docs.mapbox.com/help/glossary/zoom-level/
      return (
        // First condition - Calculating the approximate length of the displayed line in pixels
        (dist / (60000 * Math.pow(2, -1 * currentZoomLevel)) > minLinePixelLength &&
          // Second condition - Calculating the approximate span of the line in pixels
          // This will be a limiting factor if the line is very long but in a small, contained area
          distance([minLat, minLong], [maxLat, maxLong], { units: 'meters' }) /
            (60000 * Math.pow(2, -1 * currentZoomLevel)) >
            minPixelArea) ||
        currentZoomLevel >= MAX_ZOOM
      ); //If we are at max zoom, display all lines even if they are tiny.
    }
    return true;
  };

  const inBounds = function (location: any, boundsGeometry?: Feature<Polygon, Properties>) {
    if (!boundsGeometry || location?.type !== 'Point') {
      return true;
    }
    return booleanPointInPolygon(location, boundsGeometry);
  };

  const mapItems = useMemo(() => {
    return props.assets
      .filter(
        (asset: any) =>
          inBounds(asset.primaryLocation, mapViewBox) &&
          includeLine(asset.primaryLocation) &&
          asset.id &&
          asset.primaryLocation?.type &&
          asset.primaryLocation?.coordinates &&
          (!asset.recordTypeKey || !aggregateDefinitions.find(d => d.queryKey === asset.recordTypeKey)?.hiddenFromMapView)
      )
      .map(asset => {
        const aggregateDefinition = aggregateDefinitions.find(d => d.queryKey === asset.recordTypeKey);
        return {
          location: asset.primaryLocation,
          styleKey: asset.symbolKey,
          id: asset.id,
          selected: asset.id === aggId,
          aggregateType: asset.recordTypeKey,
          mapLabelProperties: getMapLabelDisplay(asset, aggregateDefinition),
          showMeasurementOnMap: getShowMeasurementOnMap(asset, aggregateDefinition),
        };
      });
  }, [mapViewBox, props.assets, currentZoomLevel]);

  //Various event handlers
  const handleSymbolClick = useCallback(
    (e: MapLayerEventType['click' | 'touchend' | 'touchstart']) => {
      const feature = e.features?.[0];
      if (feature) {
        const assetId = feature.properties?.assetId ?? '';
        setAggId(assetId);
        if (!props.mapParams) {
          setAssetsIds([assetId]);
        }
        if (!isAssetDetailsOpen) {
          setCurrentAssetId({ id: assetId, location: 'map' });
        }
      }
      onSymbolClick && onSymbolClick(e);
    },
    [history, selectedRecordType, assetData, aggregateDefinitions, setAggId]
  );
  const handleLongClick = useCallback(
    (e: MapLayerEventType['contextmenu' | 'touchstart']) => {
      const position = e.lngLat;
      setSelectedLocation({
        lon: position.lng,
        lat: position.lat,
      });
    },
    [history, selectedRecordType, assetData, aggregateDefinitions, setAggId]
  );
  const handleMaxZoomClusterClick = useCallback<MaxZoomClickHandler>((map, feature, coordinate) => {
    if (map && feature && feature.id && feature.geometry.type === 'Point') {
      const clusterId = typeof feature.id === 'string' ? parseFloat(feature.id) : feature.id;
      const source = map.getSource('symbolSource') as MapboxGl.GeoJSONSource;
      source.getClusterLeaves(clusterId, Infinity, 0, (error, features) => {
        if (error) {
          console.error(error);
          return;
        }
        const assetIds = features.map(feature => (feature.properties ? feature.properties.assetId : ''));
        setAssetsIds(assetIds);
        setCoordinates(coordinate);
      });
    }
  }, []);

  useEffect(() => {
    // For now, handling all layers as individual sources so each layer can be toggled.
    if (includeWMS) {
      const rasterSources: Record<string, MapboxGl.RasterSource> = {};
      mapServiceLayers.forEach(sl => {
        let serviceParams: Partial<MapboxGl.RasterSource> = {};
        switch (sl.serviceType) {
          case MAP_SERVICE_TYPE.WMS:
            serviceParams = {
              // Use the tiles option to specify a WMS tile source URL. https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/
              tiles: [
                `${sl.serviceUri}?bbox=${wmsParams.bbox}&${new URLSearchParams({
                  ...(Object.fromEntries(
                    Object.entries(wmsParams).filter(([k, v]) => k !== 'bbox' && v != null)
                  ) as Record<string, string>),
                  layers: sl.layer.layerName, // sl.layers.map(x => x.id).join(','), // Reimplement when multiple layer names (within each toggle) are supported by the aggregate.
                  //styles: sl.layers.map(x => x.style || '').join(','), // Reimplement when styles are added supported by the aggregate.
                }).toString()}`,
              ],
            };
            break;
          case MAP_SERVICE_TYPE.ArcGIS:
            serviceParams = {
              tiles: [
                `${trimTrailingSlash(sl.serviceUri)}/export?bbox=${arcGisParams.bbox}&${new URLSearchParams({
                  ...(Object.fromEntries(
                    Object.entries(arcGisParams).filter(([k, v]) => k !== 'bbox' && v != null)
                  ) as Record<string, string>),
                  layers: `show:${sl.layer.layerName}`, // `show:${s.layers.map(x => x.id).join(',')}`, // Reimplement when multiple layer names (within each toggle) are supported by the aggregate.
                  //styles: s.layers.map(x => x.style || '').join(','), // Reimplement when styles are added supported by the aggregate.
                }).toString()}`,
              ],
            };
            break;
          default:
            console.error(`Map Service Layer ${sl.id} has an invalid service type: ${sl.serviceType}`);
            break;
        }

        if (Object.keys(serviceParams).length) {
          rasterSources[sl.id] = { ...rasterSourceData, ...serviceParams };
        }
      });
      setMapRasterSources(rasterSources);
    }
  }, [mapServiceLayers, setMapRasterSources, includeWMS]);

  return (
    <>
      <Map
        onMaxZoomClusterClick={handleMaxZoomClusterClick}
        desiredZoom={zoom}
        desiredCenter={center}
        items={mapItems}
        selected={aggId}
        onStyleLoad={onMapLoad}
        onSymbolClick={handleSymbolClick}
        handleLongClick={handleLongClick}
        showMapControls={!!props.showLocationSearch}
        showRightControls={true}
        limitedRightControls={true}
        showMapLabels={mapLabelVisibility}
        setMapViewBox={setMapViewBox}
        mapViewBox={mapViewBox}
        symbols={props.symbols}
        forceDisableClustering={true}
        wmsControls={includeWMS && mapServiceLayers.length > 0}
        mapRasterSources={includeWMS ? mapRasterSources : undefined}
        mapVisibleMapServiceKeys={includeWMS ? visibleMapServiceKeys : undefined}
        setMapBounds={props.setMapBounds}
        setMapCenter={setMapCenter}
        hideVisibility={props.hideVisibility}
        {...mapParamsRest}
        currentZoomLevel={currentZoomLevel}
        setCurrentZoomLevel={setCurrentZoomLevel}
      />
      {!props.mapParams && (
        <AssetCardRenderer
          onFormSubmit={props.onFormSubmit}
          selectedAssetId={aggId}
          mapInstance={mapInstance}
          forceCards={true}
          onCardClose={() => setAssetsIds([])}
          ref={assetCardRef}
          assetIds={assetIds}
          setAssetsIds={setAssetsIds}
          items={items}
        />
      )}
      {props.showLoading && (
        <div className={props.classes.loader}>
          <div className={props.classes.spinnerContainer}>
            <CircularProgress className={props.classes.spinner} />
          </div>
        </div>
      )}
    </>
  );
};

const styles = () =>
  createStyles({
    loader: {
      position: 'relative',
      width: '100%',
      height: '100%',
      zIndex: 100,
      pointerEvents: 'none',
      left: 0,
      top: 0,
    },
    spinnerContainer: {
      position: 'absolute',
      left: '50%',
      bottom: '5%',
      transform: 'translateX(-50%)',
      backgroundColor: colors.black54,
      width: 56,
      height: 54,
      borderRadius: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinner: {
      color: colors.white,
    },
  });

export default withStyles(styles)(MapItemsContainer);
