import { atom, selector } from 'recoil';
import { forcedClusteringState } from './forcedClustering';
import { SingleColumnState } from '../../contexts/TableColumnContext';
import { SortDirection } from '@terragotech/react-data-grid';
const SORT_PREFERENCE_KEY = 'sortState';
const COLUMNS_PREFERENCE_KEY = 'columnsState';
const MENU_SELECTION_PREFERENCE_KEY = 'menuSelectionsState';
export const MAP_LABEL_PREFERENCE_KEY = 'mapLabelVisibility';
export const CARD_VIEW_PREFERENCE_KEY = 'cardViewVisibility';
export const PIN_CLUSTER_PREFERENCE_KEY = 'pinClusteringEnabled';
const loadPref = (key: string): unknown => {
  try {
    const menuLocalStorageState = localStorage.getItem(key);
    if (menuLocalStorageState) {
      return JSON.parse(menuLocalStorageState);
    }
  } catch (e) {
    // no worries, just ignore the whole value
  }
  return null;
};

let getDefaults = () => {
  const localStorage = loadPref(MENU_SELECTION_PREFERENCE_KEY) as any;
  return {
    [MAP_LABEL_PREFERENCE_KEY]: localStorage ? localStorage[MAP_LABEL_PREFERENCE_KEY] === true : (false as boolean),
    [CARD_VIEW_PREFERENCE_KEY]: localStorage ? localStorage[CARD_VIEW_PREFERENCE_KEY] === true : (true as boolean),
    [PIN_CLUSTER_PREFERENCE_KEY]: localStorage ? localStorage[PIN_CLUSTER_PREFERENCE_KEY] === true : (true as boolean),
  };
};

export const selectedRecordTypeState = atom<string>({
  key: 'selectedRecordTypeState',
  default: window.location.pathname.match(/^\/gen5\/([A-Z]\w+)/)?.[1] || '',
});

export const initialTableLoadState = atom<boolean>({
  key: 'initialTableLoadState',
  default: false,
});

export const columnsState = atom<{[key:string]:SingleColumnState[]}>({
  key: 'columnsState',
  default: loadPref(COLUMNS_PREFERENCE_KEY) || {} as any,
  effects: [
    ({ onSet }) => {
      onSet(newValue => {
        try {
          localStorage.setItem(COLUMNS_PREFERENCE_KEY, JSON.stringify(newValue));
        } catch (e) {}
      });
    },
  ],
});

export const sortState = atom<{[key:string]:{
  sortKey: string,
  sortDirection: SortDirection,
}}>({
  key: 'sortState',
  default: loadPref(SORT_PREFERENCE_KEY) || {} as any,
  effects: [
    ({ onSet }) => {
      onSet(newValue => {
        try {
          localStorage.setItem(SORT_PREFERENCE_KEY, JSON.stringify(newValue));
        } catch (e) {}
      });
    },
  ],
});

export const sortSelector = selector<{
  sortKey: string,
  sortDirection: SortDirection,
}>({
  key: 'sortSelector',
  get: ({ get }) => {
    let recordType = get(selectedRecordTypeState);
    let sorts = get(sortState);
    let cols = get(columnsState);
    if(sorts[recordType] && cols[recordType]?.some(c=>!['symbolKey', 'gear', 'id'].includes(c.key) && c.key === sorts[recordType].sortKey && !c.hidden)){
      return sorts[recordType];
    }
    if(cols[recordType] && cols[recordType].some(c=>!['symbolKey', 'gear', 'id'].includes(c.key) && !c.hidden)){
      const col = cols[recordType].find(c=>!['symbolKey', 'gear', 'id'].includes(c.key) && c.sticky && !c.hidden) || cols[recordType].find(c=>!['symbolKey', 'gear', 'id'].includes(c.key) && !c.hidden);
      return {
        sortKey: col!.key,
        sortDirection: 'ASC' as SortDirection,
      };
    }
    return {
      sortKey: '',
      sortDirection: 'NONE',
    };
  },
  set: ({ get, set }, newValue) => {
    let recordType = get(selectedRecordTypeState);
    let sorts = get(sortState);
    set(sortState,{...sorts,[recordType]:newValue as any})
  },
});

export const columnsSelector = selector<SingleColumnState[]>({
  key: 'columnsSelector',
  get: ({ get }) => {
    let recordType = get(selectedRecordTypeState);
    let columns = get(columnsState);
    return columns[recordType];
  },
  set: ({ get, set }, newValue) => {
    let recordType = get(selectedRecordTypeState);
    let columns = get(columnsState);
    set(columnsState,{...columns,[recordType]:newValue as SingleColumnState[]})
  },
});

export const menuSelectionsState = atom({
  key: 'menuSelectionsState',
  default: getDefaults(),
  effects: [
    ({ onSet }) => {
      onSet(newValue => {
        try {
          localStorage.setItem(MENU_SELECTION_PREFERENCE_KEY, JSON.stringify(newValue));
        } catch (e) {}
      });
    },
  ],
});

export const mapLabelSelector = selector({
  key: 'mapLabelSelector',
  get: ({ get }) => {
    let menuSelections = get(menuSelectionsState);
    return menuSelections[MAP_LABEL_PREFERENCE_KEY] as boolean;
  },
});

export const cardViewSelector = selector({
  key: 'cardViewSelector',
  get: ({ get }) => {
    let menuSelections = get(menuSelectionsState);
    return menuSelections[CARD_VIEW_PREFERENCE_KEY] as boolean;
  },
});
export const cardVisibleState = atom({
  key: 'cardVisibleState',
  default: false,
});
export const pinClusteringSelector = selector({
  key: 'pinClusteringSelector',
  get: ({ get }) => {
    let menuSelections = get(menuSelectionsState);
    let isForcedClusteringEnabled = get(forcedClusteringState);
    return (menuSelections[PIN_CLUSTER_PREFERENCE_KEY] as boolean) || isForcedClusteringEnabled;
  },
});
