import { useEffect, useMemo, useState } from 'react';
import { useMultiSelectWithProvider } from 'use-multiselect';
import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import { useAggregates } from '../contexts/AggregatesContext';
import { useAssetsTableState } from '../contexts/assetsTableStateContext';
import { AssetType } from '../contexts/AggregatesContext/types';
import { ActionsMultiSelectMenuButton } from './useMultiSelectActionsMenu';
import { useDataMapping } from './useDataMapping';
import { useConfig } from '@terragotech/gen5-shared-components';
interface MapScenariosResults {
  isDisabled?: boolean | undefined;
  disabledMessage?: string | undefined;
  isHidden?: boolean | undefined;
}
export const useConditionalMultiActions = (versionNumber: number, action?: ActionsMultiSelectMenuButton): any => {
  const dataMapping = useDataMapping();

  const { assets } = useAggregates();
  const { sortAssets } = useAssetsTableState();
  const { getSelectedCount, isSelected } = useMultiSelectWithProvider();
  const {functionDefinitions} = useConfig();

  const sortedAssets = useMemo(() => sortAssets(assets), [sortAssets, assets]);
  const data = () => {
    if (getSelectedCount(sortedAssets.length) > 0) {
      return sortedAssets.filter((asset) => isSelected(asset.id));
    }
  };
  const allInvalidatedSelections = data() || [];

  const [version, setVersion] = useState<number>(0);
  const [exceptionCount, setExceptionsCount] = useState<number>(0);
  const [validMultiAssets, setValidMultiAssets] = useState<Array<AssetType>>([]);

  useEffect(() => {
    (async () => {
      setExceptionsCount(0);
      let valid: Array<AssetType> = [];
      allInvalidatedSelections.forEach(async (selection: AssetType) => {
        if (action?.conditionalMap) {
          const accessors = dataMapping(selection).accessors;
          const { isHidden, isDisabled }: MapScenariosResults = await mapScenarios.BUTTON_STATE.evaluate(
            action.conditionalMap,
            accessors,
            functionDefinitions
          ).then((results) => {
            return { ...results };
          });

          if (isHidden || isDisabled) {
            setExceptionsCount((exceptions) => exceptions + 1);
          } else {
            valid.push(selection);
          }
        }
      });
      setValidMultiAssets(valid);
      setTimeout(() => {
        setVersion((pervious) => pervious + 1);
      });
    })();
  }, [action, versionNumber]);

  return {
    allInvalidatedSelections,
    exceptionCount,
    validMultiAssets,
    version,
  };
};
