import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMultiSelectWithProvider } from 'use-multiselect';
import AssetTable, { RecordsChanged } from '../components/AssetTable/AssetTable';
import SearchBar from '../components/SearchBar';
import {
  useConfig,
  isOneToOneRelationshipField,
  isRelationshipField,
  isOneToManyRelationshipField,
} from '@terragotech/gen5-shared-components';
import { useFilter } from '../contexts/FilterContext/filterContext';
import { useRecordType } from '../contexts/recordTypeContext';
import useSubmitCommands, { CommandType } from '../hooks/useSubmitCommand';
import { ActionsExecutorProvider } from '../hooks/useActionsExecutor';
import { useAggregates } from '../contexts/AggregatesContext';
import { colors } from '../styles/theme';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { MapAssetType } from '../contexts/AggregatesContext/types';

export interface AssetTableContainerProps {
  height: number;
  isMobileView?: boolean;
  isVertical: boolean;
  minimizeAndExpandTable?: (value: number) => void;
}

interface CurrentChangedRecords {
  [recordId: string]: {
    [fieldName: string]: unknown;
  };
}
type fieldData = Array<{ id: string }> | null | { added: string[]; removed: string[] } | string[] | string;

const SEARCH_BAR_HEIGHT = 35;

/**
 * AssetsTableContainer - Handles the data needs and logic for the Assets Table UI component.
 *
 * Re-renders the list of assets displayed when filters change
 *
 * 4 components,
 *    searchBar
 *    actionsButton
 *    selectAllCheckBox
 *    ActionsMenu
 *
 * And of course,
 *    AssetsTable
 *
 * @param heights
 * @param searchText
 * @param handleSearchText
 */

// Stores all changes to the table. Updated on change or on every keystroke in the case of text inputs.
// React-data-grid persists changes so in order to avoid unnecessary rerender triggering temporary changes are saved as a variable instead of state
let currentChangesVariable: CurrentChangedRecords = {};

const AssetsTableContainer: React.FunctionComponent<AssetTableContainerProps> = props => {
  const { height, minimizeAndExpandTable } = props;
  const { selectedRecordType, selectedRecordTypeDefinition: { queryKey: selectedRecordTypeKey } } = useRecordType();
  const { aggregateDefinitions } = useConfig();
  const assetInfo = useAggregates();
  const [changedRecords, setChangedRecords] = useState<Array<string>>([]);
  const { filterCount, resetAllFilters, drawtoolFilter } = useFilter();
  const match = useRouteMatch<{ assetId: string }>(`/${selectedRecordType}/:assetId`);
  const assetId = match?.params?.assetId;
  const {
    organizeTableOpen,
    setOrganizeTableOpen,
    handleOrganizeTableClick,
  } = useContext(AssetsDashboardContext);
  const classes = useStyles();
  const { isMultiSelectActive, setMultiSelectActive } = useMultiSelectWithProvider();
  const handleOnChange = useCallback((recordId: string, field: string, value: unknown) => {
    const changes: RecordsChanged = {
      [recordId]: {
        [field]: value,
      },
    };
    // each key returned is a recordId
    Object.keys(changes).forEach(recordId => {
      const change = changes[recordId];
      //each key here is a field name
      const fieldNames = Object.keys(change);
      // cycle through all of the fields and compare the new value to the old.
      fieldNames.forEach(fieldName => {
        currentChangesVariable = {
          ...currentChangesVariable,
          [recordId]: { ...currentChangesVariable[recordId], [fieldName]: change[fieldName] },
        };
      });
    });
    Object.keys(currentChangesVariable).forEach((id: string) => {
      setChangedRecords(Object.keys(currentChangesVariable[id]));
    });
  }, []);

  useEffect(() => {
    setMultiSelectActive(drawtoolFilter);
  }, [drawtoolFilter]);
  const assetsWithAppliedChanges = useMemo(() => {
    if (!assetInfo.recordTypesData) {
      return [];
    }
    // If there are no changes, then skip checking anything and just return the assets as-is
    if (Object.keys(currentChangesVariable).length === 0) {
      return assetInfo.assets;
    }
    return assetInfo.assets.map(asset => {
      if (currentChangesVariable[asset.id]) {
        return { ...asset, ...currentChangesVariable[asset.id] };
      } else {
        return asset;
      }
    });
    // Dependency array must contain currentChangesVariable even, because it's a workaround to avoid unnecesary rerenders
    // eslint-disable-next-line
  }, [assetInfo.assets, currentChangesVariable, assetInfo.recordTypesData]);

  const { submitCommands } = useSubmitCommands();

  const handleSave = useCallback(() => {
    const changeCommands = Object.keys(currentChangesVariable).map<CommandType>(aggregateId => {
      const computedCommandData: Record<string, unknown> = {};
      Object.keys(currentChangesVariable[aggregateId]).forEach(fieldName => {
        let fieldData: fieldData = currentChangesVariable[aggregateId][fieldName] as Array<{ id: string }>;
        if (isRelationshipField(aggregateDefinitions, selectedRecordType, fieldName)) {
          if (isOneToOneRelationshipField(aggregateDefinitions, selectedRecordType, fieldName)) {
            // One to One
            fieldData = fieldData && fieldData.length > 0 ? fieldData[0].id || null : null;
          } else if (isOneToManyRelationshipField(aggregateDefinitions, selectedRecordType, fieldName)) {
            // One to Many
            const previousRecordIds: string[] = (assetInfo.assets.find(
              record => record?.id === aggregateId
            )?.[fieldName] as Array<{ id: string }>).map(record => record.id);
            const newRecordIds: string[] = fieldData.map((item: { id: string }) => item.id);
            fieldData = {
              // Loop through new id's, return if record didn't exist before
              added: newRecordIds.filter((record: string) => !previousRecordIds.includes(record)),
              // Loop through old id's, return if they no longer exist in new
              removed: previousRecordIds.filter((record: string) => !newRecordIds.includes(record)),
            };
          } else {
            fieldData = (fieldData as Array<{ id: string }>).map(x => {
              return x.id;
            });
          }
        }
        computedCommandData[fieldName] = fieldData;
      });
      return {
        target: aggregateId,
        version: -1,
        aggregateType: selectedRecordType,
        commandName: 'AdminUpdate',
        commandData: computedCommandData,
        isSynchronous: false, // Don't need high polling rate for this
        hasNoAggregateIdCommand: false,
      };
    });
    assetInfo.setMultiSelectCommandsLoading(true);
    submitCommands(changeCommands)
      .then(() => {
        assetInfo.setMultiSelectCommandsLoading(false);
        //TODO: Update adminUpdate command to use graphQL response to update the data set rather than requery.
        // setTimeout(() => {
        //   assetInfo.refetchAll().then(() => {
        //     setIsLoading(false);
        //TODO: This timeout is badd, it probably doens't work well for most things
        //    But often times it should allow us to continute displaying what the user changed until the data is actually refreshed.
        setTimeout(() => {
          currentChangesVariable = {};
          setChangedRecords([]);
        }, 1000);
        //   });
        // }, 500);
      })
      .catch(() => {
        // If errors with the update query, revert the cell value
        currentChangesVariable = {};
        setChangedRecords([]);
        assetInfo.setMultiSelectCommandsLoading(false);
      });
    //TODO: Update this to make sure the new data is reflected in the UI, rather than relying on keeping the changes in place.
  }, [submitCommands, selectedRecordType, aggregateDefinitions, assetInfo]);

  const cancelChanges = useCallback(() => {
    currentChangesVariable = {};
    setChangedRecords([]);
  }, []);

  return (
    <div className={classes.tableContainer}>
      <ActionsExecutorProvider>
        {assetInfo.multiSelectCommandsLoading && (
          <Backdrop className={classes.backdrop} open={assetInfo.multiSelectCommandsLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <SearchBar
          height={SEARCH_BAR_HEIGHT}
          showing={assetInfo.assets.length}
          totalAssets={assetInfo.currentTypeAssetsCount}
          numberOfFilters={filterCount}
          clearAllFilters={resetAllFilters}
          handleColumnFilterOpen={handleOrganizeTableClick}
          checked={isMultiSelectActive}
          setChecked={setMultiSelectActive}
          isLoadingData={assetInfo.loading}
          isMobileView={props.isMobileView}
          isVertical={props.isVertical}
          minimizeAndExpandTable={minimizeAndExpandTable}
        />
        <AssetTable
          rowData={assetsWithAppliedChanges as MapAssetType[]}
          height={height}
          id={assetId}
          columnFilterOpen={organizeTableOpen}
          setColumnFilterOpen={setOrganizeTableOpen}
          changedRecords={changedRecords}
          onEditCanceled={cancelChanges}
          onEditSaved={handleSave}
          onChange={handleOnChange}
          selectedRecordTypeKey={selectedRecordTypeKey}
        />
      </ActionsExecutorProvider>
    </div>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: colors.white,
    },
    tableContainer: {
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
  })
);
export default AssetsTableContainer;
