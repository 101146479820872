import {
  MapAssetType,
  MapServiceType,
  MapServiceLayerType,
  WithAggregateName,
  pageInfo,
  recordData,
  GraphQlAssetType,
} from './types';

import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import _ from 'lodash';
import { MAP_SERVICE_DEFAULT_KEYS } from '@terragotech/gen5-shared-utilities';

export const filterNonMapAggregates = (aggregateDefinitions: AggregateDefinition[]) =>
  aggregateDefinitions.filter(definition => !['Folder', 'Project'].includes(definition.name));

/**
 * Creates object that has an index structure with data generated by generator
 *
 * @param aggregateDefinitions
 * @param generator
 *
 * The resulted object has following structure
 * {
 *  [aggregateKey]: dataGeneratedByGenerator
 * }
 */
export const createWithAggregateNameObject = <T extends unknown>(
  aggregateDefinitions: AggregateDefinition[],
  generator: (definition: AggregateDefinition) => T
): WithAggregateName<T> =>
  aggregateDefinitions.reduce<WithAggregateName<T>>((result, definition) => {
    const key = definition.queryKey;
    result[key] = generator(definition);
    return result;
  }, {});

export const filterMapServiceAssets = (assets: MapAssetType[]) =>
  assets.filter(x => x.recordTypeKey === MAP_SERVICE_DEFAULT_KEYS.recordTypeKey) as MapServiceType[];

export const reduceMapServicesToLayers = (services: MapServiceType[]) =>
  services.reduce((acc, cur, i, arr) => {
    cur.layers.forEach(l =>
      acc.push(
        _.omit({ ...cur, id: `${cur.id}-${l.layerName}`, label: l.layerTitle || l.layerName, layer: l }, 'layers')
      )
    );
    return acc;
  }, [] as MapServiceLayerType[]);

export const extractDataFromResult = (
  data: { edges: { node: GraphQlAssetType; cursor: string }[]; pageInfo: pageInfo },
  recordTypeKey: string
) => {
  const assetData = data.edges.map(edge => ({
    ...edge.node,
    recordTypeKey,
    primaryLocation: edge.node.primaryLocation?.geojson || undefined,
    cursor: edge.cursor,
  }));
  return {
    [recordTypeKey]: {
      assetData,
      pageInfo: data.pageInfo,
    },
  };
};

export const checkForExistingData = (checkExsistingData: boolean, recordData: recordData) => {
  if (checkExsistingData) {
    if (recordData?.assetData?.length) {
      return recordData.assetData.length !== 0;
    } else return false;
  }
  return false;
};

export const getAssetsFromResults = (edges: { node: GraphQlAssetType }[], recordTypeKey: string) => {
  const assetData = edges.map(edge => ({
    ...edge.node,
    recordTypeKey,
    primaryLocation: edge.node.primaryLocation?.geojson || undefined,
  }));
  return assetData;
};
