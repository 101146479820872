import { useCallback, useEffect, useState } from 'react';
import { getSelectableAggregateDefinitions } from '../../../../../hooks/sectionsHooks/useMapVisibilitySection';
import { useAggregates } from '../../../../../contexts/AggregatesContext';
import { useConfig } from '@terragotech/gen5-shared-components';
import { Box, Checkbox, Link, Theme, Typography, makeStyles } from '@material-ui/core';
import { colors } from '../../../../../styles/theme';
import { tabProps } from './Accordions';
import _ from 'lodash';
import { useCommonStyles } from './useStyles';

const RecordTypeTab = ({ SearchbarSection }: tabProps) => {
  const config = useConfig();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const aggregateDefinitions = getSelectableAggregateDefinitions(config);
  const [searchText, setSearchText] = useState('');
  const [canFocusOut, setCanFocusOut] = useState(false);
  const [visibleRecordTypes, setVisibilityRecordTypes] = useState<string[]>(
    aggregateDefinitions.map(definition => definition.plural)
  );

  const { setVisibleAggregateTypesNames, visibleAggregateTypesNames } = useAggregates();
  const mapVisibility = aggregateDefinitions.map(definition => visibleAggregateTypesNames?.includes(definition.name));

  const onFilter = () => {
    if (searchText && searchText !== '') {
      const records = aggregateDefinitions
        .filter(definition => _.toLower(definition.plural).includes(_.toLower(searchText)))
        .map(o => o.plural);
      setVisibilityRecordTypes(records);
    } else {
      setVisibilityRecordTypes(aggregateDefinitions.map(definition => definition.plural));
    }
  };

  useEffect(() => {
    onFilter();
  }, [searchText, setVisibilityRecordTypes]);

  const toggleMapVisibility = useCallback(
    (value: string) => {
      const definition = aggregateDefinitions.find(definition => definition.plural === value);
      if (definition) {
        if (visibleAggregateTypesNames.includes(definition.name)) {
          setVisibleAggregateTypesNames(visibleAggregateTypesNames.filter(name => name !== definition.name));
        } else {
          setVisibleAggregateTypesNames([...visibleAggregateTypesNames, definition.name]);
        }
      }
    },
    [setVisibleAggregateTypesNames, visibleAggregateTypesNames]
  );

  const isChecked = (index: number) => {
    return mapVisibility[index];
  };

  const toggleRecords = () => {
    if (visibleAggregateTypesNames?.length === 0) {
      setVisibleAggregateTypesNames(_.map(aggregateDefinitions, 'name'));
    } else {
      setVisibleAggregateTypesNames([]);
    }
  };

  const selectedItems = _.chain(visibleRecordTypes)
    .map((o, i) => (isChecked(i) ? 1 : 0))
    .sum()
    .value();

  return (
    <Box className={commonClasses.container}>
      <SearchbarSection searchValue={searchText} searchOnChange={setSearchText} focusOut={canFocusOut} />
      <Box className={commonClasses.outer}>
        <Box
          className={`${classes.detailContainer} ${commonClasses.detailContainer}`}
          onScroll={() => setCanFocusOut(true)}
        >
          <Link className={commonClasses.linkBtn} component="button" underline="always" onClick={() => toggleRecords()}>
            {visibleAggregateTypesNames.length ? `Deselect All (${selectedItems})` : 'Select All'}
          </Link>
          {visibleRecordTypes.map((definition: string, index: number) => {
            const checked = isChecked(index);
            return (
              <Box key={`${definition}-${index}`} className={classes.record}>
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={() => {
                    toggleMapVisibility(definition);
                  }}
                />
                <Typography className={classes.recordLabel}>{definition}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => {
  return {
    record: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      minHeight: 18,
      '& .MuiIconButton-root': {
        padding: 0,
        paddingRight: 12,
      },
    },
    recordLabel: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black0,
      lineHeight: 'normal',
      fontFamily: 'Roboto',
    },
    detailContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 18,
    },
  };
});
export default RecordTypeTab;
