import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useContext, useMemo } from 'react';
import { useTableColumns } from '../../contexts/TableColumnContext';
import BottomActionBar from './BottomActionBar';
import FluidStyledTable from '../StyledTable/FluidStyledTable';
import { Column, FilterRendererProps } from '../../hooks/tableHooks/useColumns';
import SlideDialog from '../SlideDialog';
import ColumnFilter from '../Filters/ColumnFilter/ColumnFilter';
import ColumnSearch from './ColumnSearch';
import { moveColumns } from '../StyledTable/utils';
import { EditModeContext } from '../../contexts/editModeContext';
import { useSelectedProject } from '../../contexts/selectedProjectContext';
import magicText from 'i18next';
import { useFilter } from '../../contexts/FilterContext/filterContext';
import { AssetType, MapAssetType } from '../../contexts/AggregatesContext/types';
import { useAssetsTableState } from '../../contexts/assetsTableStateContext';
import { isArray, times } from 'lodash';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';
import { rowHeight } from '../StyledTable/StyledTable';
import { useRecoilValue } from 'recoil';
import { selectedItemsState } from '../../recoil/atoms/mapMaintenance';

const TABLE_HEADER_HEIGHT = 78;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  emptyTable: {
    width: '100%',
    height: `calc(100% - ${TABLE_HEADER_HEIGHT}px)`,
    position: 'fixed',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export type RecordsChanged = {
  [recordId: string]: {
    [field: string]: unknown;
  };
};

export interface AssetTableProps {
  height: number;
  rowData: MapAssetType[];
  id?: string;
  columnFilterOpen: boolean;
  setColumnFilterOpen: (value: boolean) => void;
  changedRecords?: Array<string>;
  onEditSaved: () => void;
  onEditCanceled: () => void;
  onCellBlur?: (recordId: string, field: string, value: unknown) => void;
  onChange?: (recordId: string, field: string, value: unknown) => void;
  selectedRecordTypeKey: string;
}

const getNumberOfStickyColumns = (columns: ReadonlyArray<Column<AssetType>>) => {
  return columns.reduce<number>((r, x) => r + (x.sticky ? 1 : 0), 0);
};

const EmptyView = (props: { filterCount: number; selectedProjects: string[]; emptyContainerStyle: string }) => {
  return (
    <div className={props.emptyContainerStyle}>
      <>
        {magicText.t('AssetTable.noRowsFiltered')}
      </>
    </div>
  );
};

/**
 *
 * AssetTable - Displays list of filtered assets on bottom half of the screen.
 *
 * Data for the table is passed down from the container as "rowData"
 *
 * There are many props passed to assetTable from its container
 *
 * @param height
 * @param columnDefs
 * @param rowData
 * @param assetId
 * @param columnFilterOpen
 * @param setColumnFilterOpen
 * @param setColumnDefs
 * @param allAvailableColumns
 * @param setAllCols
 * @param shouldSelectAllNodes
 * @param setShouldSelectAllNodes
 * @param setSelected
 * @param selected
 * @param setValue
 * @param changedAssets
 * @param setChangedAssets
 */
const AssetTable: FunctionComponent<AssetTableProps> = props => {
  const {
    height,
    rowData,
    changedRecords,
    onEditCanceled,
    onEditSaved,
    id,
    columnFilterOpen,
    setColumnFilterOpen,
    onChange,
    selectedRecordTypeKey,
  } = props;
  const classes = useStyles();
  const { isMobileView } = useContext(AssetsDashboardContext);
  const { setEditModeActive, setSaveActive, editModeActive, editModeData, setAssetEditModeActive } =
    useContext(EditModeContext);
  const { columns, setColumnOrder } = useTableColumns();
  const { filterCount } = useFilter();
  const { selectedProjects } = useSelectedProject();
  const { setSortColumn, setSortDirection } = useAssetsTableState();
  const selectedItems = useRecoilValue<MapAssetType[]>(selectedItemsState);

  const summaryItems = useMemo(() => 
    selectedItems.length === 1 && selectedItems[0].recordTypeKey === selectedRecordTypeKey ? selectedItems : undefined
    // selectedItems.filter(asset => asset.recordTypeKey === selectedRecordTypeKey) // Note: Cluster support temporarily disabled.
  , [selectedItems, selectedRecordTypeKey]);

  const handleColumnMove = useCallback(
    (fromIndex: number, toIndex: number) => {
      const numberOfPinned = getNumberOfStickyColumns(columns);
      const newColumns = moveColumns(columns, fromIndex, toIndex);
      const numberOfPinnedDelta = (() => {
        if (fromIndex >= numberOfPinned && toIndex < numberOfPinned) return 1;
        if (fromIndex < numberOfPinned && toIndex >= numberOfPinned) return -1;
        return 0;
      })();
      setColumnOrder(
        newColumns.map((column, index) => ({
          ...column,
          key: column.key,
          sticky: index < numberOfPinned + numberOfPinnedDelta,
        }))
      );
    },
    [setColumnOrder, columns]
  );

  const handleCellEditStart = useCallback(() => {
    setSaveActive(false);
  }, [setSaveActive]);

  const handleCellEditStop = useCallback(() => {
    setSaveActive(true);
  }, [setSaveActive]);

  const stopCellEditing = useCallback(() => {
    setEditModeActive(false);
    setAssetEditModeActive(false);
  }, [setEditModeActive]);

  const onDiscardClick = useCallback(() => {
    stopCellEditing();
    onEditCanceled();
  }, [stopCellEditing, onEditCanceled]);

  const onSaveClick = useCallback(() => {
    stopCellEditing();
    onEditSaved();
  }, [stopCellEditing, onEditSaved]);

  const tableTopSpace = isMobileView ? 111 : 54;
  const bottomRecordTypeBarHeight = 36;

  const selectedRowComparator = useCallback((asset: AssetType) => asset.id === id, [id]);

  const editRowComparator = useCallback(
    (asset: AssetType) =>
      isArray(editModeData) ? editModeData.some(x => x.id === asset.id) : asset.id === editModeData?.id,
    [editModeData]
  );

  const filterRenderer = useCallback(
    ({ column }: FilterRendererProps<AssetType>) => <ColumnSearch displayName={column.name} field={column.key} />,
    []
  );

  const tableHeight = height - (bottomRecordTypeBarHeight + tableTopSpace);
  const data = useMemo(() => {
    if (rowData?.length > 0) {
      const data = [...rowData];
      const dummyData = {
        id: '',
      };
      const length = rowData.length;
      const finalFilling = Math.floor(tableHeight / rowHeight);
      const remaining = Math.floor(finalFilling - length - 2);
      const timesFilling = remaining > 0 ? remaining : 0;
      times(timesFilling, n => {
        data.push(dummyData as MapAssetType);
      });
      return data;
    }
    return [];
  }, [rowData, tableHeight]);

  return (
    <div className={classes.root}>
      {!isMobileView && (
        <SlideDialog open={columnFilterOpen} onClose={() => setColumnFilterOpen(false)}>
          <ColumnFilter open={columnFilterOpen} setColumnFilterOpen={setColumnFilterOpen} isMobileView={isMobileView} />
        </SlideDialog>
      )}
      <FluidStyledTable<AssetType>
        assetTable
        data={data?.length > 0 ? data : rowData}
        summaryData={summaryItems}
        columns={columns}
        height={tableHeight}
        onChange={onChange}
        selectedRowComparator={!editModeActive ? selectedRowComparator : undefined}
        filterRenderer={filterRenderer}
        onColumnsMove={handleColumnMove}
        onInput={handleCellEditStart}
        onBlur={handleCellEditStop}
        emptyView={() => (
          <EmptyView
            selectedProjects={selectedProjects}
            filterCount={filterCount}
            emptyContainerStyle={classes.emptyTable}
          />
        )}
        setSortColumn={setSortColumn}
        setSortDirection={setSortDirection}
        editRowComparator={editModeActive ? editRowComparator : undefined}
        isAssetTable
      />
      <BottomActionBar
        onDiscardClick={onDiscardClick}
        onSaveClick={onSaveClick}
        label={
          isArray(editModeData)
            ? editModeData.length === 1
              ? editModeData[0].label
              : `${editModeData.length} Assets`
            : editModeData?.label
        }
        changedRecords={changedRecords}
      />
    </div>
  );
};
export default React.memo(AssetTable);
