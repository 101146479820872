import { useMemo, useState, useEffect, useContext } from 'react';
import { HeaderRendererProps, SortDirection } from '@terragotech/react-data-grid';
import useColumns, { Column, FilterRendererProps, ID_COLUMN_KEY } from './tableHooks/useColumns';
import useDraggableColumns from './tableHooks/useDraggableColumns';
import useSort from './tableHooks/useSort';
import { isEqual } from 'lodash';
import { v4 as uuid4 } from 'uuid';
import StyledTableEditor from '../components/StyledTable/Editors/StyledTableEditor';
import { EditModeContext } from '../contexts/editModeContext';
import { useRecoilState } from 'recoil';
import { sortSelector } from '../recoil/atoms';

export type TableGeneratedClasses = { cell: string; editableCell: string };

type primitiveType = string | number | boolean;

type Stringable =
  | ({
      toString: () => string;
    } & Record<string, any>)
  | primitiveType;

export type ValueType = Stringable | Stringable[] | { id: string; label: string; title: string };

/**
 * TableData is the shape of an entire row of data, so in this case "index" is a column name and valuetype is the value of that column
 */
export type TableData = {
  [index: string]: ValueType;
};

export interface UseTableProps<Data extends TableData> {
  columns: ReadonlyArray<Column<Data>>;
  data: ReadonlyArray<Data>;
  filterRenderer: (props: FilterRendererProps<Data>) => JSX.Element;
  headerRenderer: (
    props: HeaderRendererProps<Data, unknown> & {
      onColumnsReorder: (sourceKey: string, targetKey: string) => void;
      sortDirection?: string;
    }
  ) => JSX.Element;
  editable: boolean;
  editor: typeof StyledTableEditor;
  handleColumnsMove: (fromIndex: number, toIndex: number) => void;
  setEditModeOn?: (row: Data) => void;
  isAssets: boolean;
}

const addIndexToData = <Data extends TableData>(data: ReadonlyArray<Data>, indexKeyName: string) =>
  data.map((x, index) => ({
    ...x,
    [indexKeyName]: index,
  })) as readonly Data[];

export const sort = <Data extends TableData>(data: ReadonlyArray<Data>, sortDirection: string, sortKey: string) => {
  if (sortDirection && sortDirection !== 'NONE') {
    const sorted: readonly Data[] = [...data].sort((a, b) => {
      const aValue = a[sortKey] ? a[sortKey].toString() : '';
      const bValue = b[sortKey] ? b[sortKey].toString() : '';
      return aValue.localeCompare(bValue);
    });
    if (sortDirection === 'DESC') {
      const reversed: readonly Data[] = [...sorted].reverse();
      return reversed;
    }
    return sorted;
  }
  return data;
};

const generateTableCssClasses: () => TableGeneratedClasses = () => {
  const id = uuid4().replace(/-/g, '');
  return {
    cell: `cell_${id}`,
    editableCell: `cell_editable_${id}`,
  };
};

export const useTable = <Data extends TableData>(props: UseTableProps<Data>) => {
  const classes: TableGeneratedClasses = useMemo(generateTableCssClasses, []);
  const [{sortKey:sortKeyAtom,sortDirection:sortDirectionAtom},setSort] = useRecoilState(sortSelector)

  // This section sets a default sort for the columns. It's not a great way to do this
  const sortColumns = useMemo(() => {
    //prefer sticky columns
    const newCols = [...props.columns].sort((a, b) => {
      return a.sticky === b.sticky ? 0 : a.sticky ? -1 : 1;
    });
    return newCols.map(col => col.key).filter(key => !['symbolKey', 'gear', 'id'].includes(key));
  }, [props.columns]);

  const { sortDirection:sortDirectionLocal, sortKey:sortKeyLocal, handleSort } = useSort(
    sortColumns.length > 0 ? sortColumns[0] : '',
  );

  const {sortKey,sortDirection} = useMemo(()=>{
    return props.isAssets ? {sortKey:sortKeyAtom,sortDirection:sortDirectionAtom} : {sortKey:sortKeyLocal,sortDirection:sortDirectionLocal}
  },[sortKeyAtom,sortDirectionAtom,sortKeyLocal,sortDirectionLocal])

  useEffect(() => {
    if (!props.isAssets && !sortColumns.includes(sortKey)) {
      handleSort({sortKey:sortColumns[0],sortDirection:'ASC'});
    }
  }, [handleSort, sortColumns, sortKey]);

  const [propsData, setPropsData] = useState(props.data);
  const { editModeActive } = useContext(EditModeContext);

  useEffect(() => {
    if (!isEqual(props.data, propsData)) {
      setPropsData(props.data);
    }
  }, [props.data, propsData]);

  const dataWithIndex = useMemo(() => addIndexToData(propsData, ID_COLUMN_KEY), [propsData]);

  const editorOptions = useMemo(() => ({ editOnClick: editModeActive }), [editModeActive]);

  const { visibleColumns, hiddenColumns, columnsInOrder } = useColumns({
    columns: props.columns,
    editable: props.editable,
    editor: props.editor,
    filterRenderer: props.filterRenderer,
    classes,
    editorOptions,
    setEditModeOn: props.setEditModeOn,
  });

  const { draggableColumns } = useDraggableColumns({
    columns: columnsInOrder,
    visibleColumns,
    hiddenColumns,
    headerRenderer: props.headerRenderer,
    sortKey,
    sortDirection,
    handleColumnsMove: props.handleColumnsMove,
  });

  return {
    sortColumn: sortKey,
    sortDirection,
    setSort: props.isAssets ? setSort : handleSort,
    columnsToRender: draggableColumns,
    dataToRender: dataWithIndex,
  };
};

export default useTable;
