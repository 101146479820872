/* eslint-disable react-hooks/exhaustive-deps */
import turfBbox from '@turf/bbox';
import turfDistance from '@turf/distance';
import { lineString, Polygon, Properties } from '@turf/helpers';
import { Feature as MapFeature, GeoJsonProperties } from 'geojson';
import _ from 'lodash';
import * as MapboxGl from 'mapbox-gl';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import AssetCardRenderer from '../components/Map/AssetCard/AssetCardRenderer';
import Map, { MapItem, MaxZoomClickHandler } from '../components/Map/component/Map';
import { useAggregates } from '../contexts/AggregatesContext';
import { useAssetCards } from '../contexts/assetCardContext';
import { MapBoundsContext, useConfig } from '@terragotech/gen5-shared-components';
import { FormParentValueContext } from '../contexts/formParentValueContext';
import { MapAssetType } from '../contexts/AggregatesContext/types';
import { useSetRecoilState } from 'recoil';
import { mapSelectBoundsState } from '../recoil/atoms/mapMaintenance';
export interface AggregateSelection {
  id?: string;
  label?: string;
  type?: string;
}

export interface AggregateSelectorMapContainerProps {
  height?: unknown;
  width?: number;
  selectedAggregate?: AggregateSelection | null;
  onAggregateSelected: (aggregate: AggregateSelection) => void;
  selectableAggregateTypes?: string[];
  getValueFromSymbolClick?: (label: string) => void;
  assetData: MapAssetType[];
}

/**
 * @remarks Obtains variables for props, returns Map with props
 *
 * @param width
 * @param height
 * @param searchText
 */
const AggregateSelectorMapContainer: React.FunctionComponent<AggregateSelectorMapContainerProps> = props => {
  const {
    height,
    width,
    selectedAggregate,
    onAggregateSelected,
    selectableAggregateTypes,
    getValueFromSymbolClick,
    assetData,
  } = props;

  const [mapInstance, setMapInstance] = useState<MapboxGl.Map | null>(null);
  const { setCoordinates, setLocationAssetIds, locationAssetIds } = useAssetCards();
  const setMapBounds = useSetRecoilState(mapSelectBoundsState);
  const selectedItem = useRef<any>(null);
  const [selectedClusterId, setSelectedClusterId] = useState(0);
  const [mapViewBox, setMapViewBox] = useState<MapFeature<Polygon, Properties>>();
  // Adding custom mapbox map control for current location button, because it is not available in our mapbox-react library
  // https://docs.mapbox.com/mapbox-gl-js/api/markers/#geolocatecontrol.event:trackuserlocationstart
  const onMapLoad: MapEvent = map => {
    setMapInstance(map);
  };
  const [mapAssets, setMapAssets] = useState<MapAssetType[]>([]);
  useEffect(() => {
    const clusterAssets = assetData.filter(data => locationAssetIds.includes(data.id));
    setMapAssets(clusterAssets);
  }, [assetData, locationAssetIds]);
  const configContext = useConfig();
  const { parentValue } = useContext(FormParentValueContext);
  const { aggregateDefinitions } = useConfig();
  const { currentZoomLevel: contextCurrentZoomLevel } = useContext(MapBoundsContext);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(contextCurrentZoomLevel);
  const assetRecord = useAggregates();
  //Determining Map Center
  const selectedAggregateObject = useMemo(() => {
    return assetData.find(item => item.id === selectedAggregate?.id);
  }, [assetData, selectedAggregate]);
  //TODO: Similar function in AssetCardRenderer, refactor and export to utils
  const geoJsonToCoordinates = useCallback(
    (geoJson: any): [number, number] => {
      if (geoJson.type === 'Point') {
        if (
          geoJson.coordinates &&
          typeof geoJson.coordinates[0] === 'number' &&
          typeof geoJson.coordinates[1] === 'number'
        )
          return [geoJson.coordinates[0], geoJson.coordinates[1]];
      }
      if (geoJson.type === 'LineString') {
        let coords = geoJson.coordinates;
        //Move map to fit both points
        let line = lineString(coords);
        let box = turfBbox(line);
        if (mapInstance) {
          mapInstance.fitBounds([
            [box[0], box[1]],
            [box[2], box[3]],
          ]);
        }
        //Center Map on lat+lon of first point
        if (coords && typeof coords[0][0] === 'number' && typeof coords[0][1] === 'number')
          return [coords[0][0], coords[0][1]];
      }
      if (geoJson && typeof geoJson.lat === 'number' && typeof geoJson.lon === 'number') {
        return [geoJson.lon, geoJson.lat];
      }
      //Default
      return [
        parseFloat(configContext.initialMapExtents.lon) || 0,
        parseFloat(configContext.initialMapExtents.lat) || 0,
      ];
    },
    [mapInstance]
  );

  //Determine Center by priority of Selected Aggregate -> Parent Aggregate -> Default Config Lat Lon
  const getCenter = useCallback((): [number, number] => {
    if (selectedAggregateObject) {
      return geoJsonToCoordinates(selectedAggregateObject.primaryLocation);
    } else if (parentValue) {
      return geoJsonToCoordinates(parentValue);
    }
    return [parseFloat(configContext.initialMapExtents.lon) || 0, parseFloat(configContext.initialMapExtents.lat) || 0];
  }, [selectedAggregate, selectedAggregateObject, parentValue, geoJsonToCoordinates]);

  const [center] = useState<[number, number]>(getCenter());
  const mapItems: Array<MapItem> = assetData
    .filter(
      (asset: any) =>
        asset.id &&
        asset.primaryLocation?.type &&
        asset.primaryLocation?.coordinates &&
        (!asset.recordTypeKey || !aggregateDefinitions.find(d => d.queryKey === asset.recordTypeKey)?.hiddenFromMapView)
    )
    .map(asset => {
      return {
        location: asset.primaryLocation,
        styleKey: asset.symbolKey,
        id: asset.id,
        selected: asset.id === selectedAggregate?.id,
        aggregateType: asset.recordTypeKey,
      };
    });
  const UpdatePointValue = (props: { id: string; label: string }) => {
    const { id, label } = props;
    if (label) {
      getValueFromSymbolClick && getValueFromSymbolClick(label);
    }
    if (id) {
      const AssetLabel = assetRecord.assets.find(agg => agg.id === id)?.['label'] as string;
      getValueFromSymbolClick && getValueFromSymbolClick(AssetLabel);
    }
  };
  const handleSymbolClick = useCallback<(e: MapboxGl.MapLayerMouseEvent | MapboxGl.MapLayerTouchEvent) => void>(e => {
    //update our selected asset/aggregate whenever one is clicked.
    // Eventually we will need to limit this to the available aggregate types
    if (e.features && e.features?.length > 0) {
      const properties: GeoJsonProperties = e.features[0].properties;
      if (properties) {
        const { assetId, label, aggregateType } = properties;
        UpdatePointValue({ id: assetId, label: label });
        const definition = aggregateDefinitions.find(d => d.queryKey === aggregateType);
        if (
          _.isEmpty(selectableAggregateTypes) ||
          selectableAggregateTypes?.find((type: string) => type === definition?.name)
        ) {
          onAggregateSelected({ id: assetId, label, type: aggregateType });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (selectedAggregate) {
      selectedItem.current = mapItems.find(asset => asset.id === selectedAggregate.id);
    }
  }, [selectedAggregate]);

  const handleMaxZoomClusterClick = useCallback<MaxZoomClickHandler>((map, feature, coordinate) => {
    if (map && feature && feature.id && feature.geometry.type === 'Point') {
      const clusterId = typeof feature.id === 'string' ? parseFloat(feature.id) : feature.id;
      const source = map.getSource('symbolSource') as MapboxGl.GeoJSONSource;
      source.getClusterLeaves(clusterId, Infinity, 0, (error, features) => {
        if (error) {
          console.error(error);
          return;
        }
        const assetIds = features.map(feature => (feature.properties ? feature.properties.assetId : ''));
        setLocationAssetIds(assetIds);
        setCoordinates(coordinate);
      });
    }
  }, []);
  const onSelect = (item: any) => {
    UpdatePointValue({ id: item.assetId, label: item?.label });
    const definition = aggregateDefinitions.find(d => d.name === item.aggregateType);
    if (
      _.isEmpty(selectableAggregateTypes) ||
      selectableAggregateTypes?.find((type: string) => type === definition?.name)
    ) {
      onAggregateSelected({ id: item.assetId, type: item.aggregateType });
    }
  };
  return (
    <>
      <Map
        height={height}
        width={width}
        items={mapItems}
        onStyleLoad={onMapLoad}
        onSymbolClick={handleSymbolClick}
        isSelectedEndpoint
        mapEditor={true}
        setSelectedClusterId={setSelectedClusterId}
        selectedClusterId={selectedClusterId}
        onMaxZoomClusterClick={handleMaxZoomClusterClick}
        setMapViewBox={setMapViewBox}
        mapViewBox={mapViewBox}
        setMapBounds={setMapBounds}
        currentZoomLevel={currentZoomLevel}
        setCurrentZoomLevel={setCurrentZoomLevel}
        selected={selectedAggregate?.id}
      >
        {
          <AssetCardRenderer
            selectedAssetId={selectedAggregate?.id}
            mapInstance={mapInstance}
            items={mapAssets}
            selectableAggregateTypes={selectableAggregateTypes}
            onSelect={onSelect}
            mapEditor={true}
            selectedAggregateId={selectedAggregate?.id}
            assetIds={locationAssetIds}
            setAssetsIds={setLocationAssetIds}
            onCardClose={() => setSelectedClusterId(0)}
          />
        }
      </Map>
    </>
  );
};
export default AggregateSelectorMapContainer;
