import { FunctionComponent, useCallback, useContext, useState } from 'react';
import { useMultiSelectWithProvider } from 'use-multiselect';
import { AssetType } from '../../contexts/AggregatesContext/types';
import ThreeDotMenuCellRenderer from '../StyledTable/ThreeDotsMenuCell';
import useConditionalTableActionsMenu from '../../hooks/useConditionalTableActionsMenu';
import { EditModeContext } from '../../contexts/editModeContext';
import { useFilter } from '../../contexts/FilterContext/filterContext';
import { useAssetsTableState } from '../../contexts/assetsTableStateContext';
export interface ThreeDotMenuCellProps {
  data: AssetType;
  isSummaryRow?: boolean;
}

const AssetsThreeDotMenuCellRenderer: FunctionComponent<ThreeDotMenuCellProps> = props => {
  const { data, isSummaryRow } = props;
  const { isMultiSelectActive, isSelected, toggleSelected } = useMultiSelectWithProvider();
  const [isClosed, setIsClosed] = useState(true);
  const { setEditModeActive, editModeActive, setEditModeData, setAssetEditModeActive } = useContext(EditModeContext);
  const closeMenu = useCallback(() => setIsClosed(true), []);
  const handleOpen = useCallback(() => setIsClosed(false), []);
  const {multiSelectSelectedAssets} = useFilter()
  const {setActionsMenuRefresh, isSelectAll, setIsSelectAll} = useAssetsTableState()
  const { singleActions, statusText } = useConditionalTableActionsMenu({ closeMenu, isClosed, target: data });
  const onEditClick = () => {
    setEditModeData({ id: data.id, label: data.label });
    setEditModeActive(true);
    setAssetEditModeActive(true);
  };

  const handleSelect = () =>{
    toggleSelected(data.id)
    const exisitingAsset = multiSelectSelectedAssets.current
    const assetIndex = exisitingAsset.findIndex(asset=> asset.id === data.id)
    if(assetIndex === -1){
      multiSelectSelectedAssets.current.push(data)
    }
    else{
      multiSelectSelectedAssets.current.splice(assetIndex,1)
      if(isSelectAll){
        setIsSelectAll(false)
      }
    }
    setActionsMenuRefresh(prev => prev + 1)
  }

  return (
    <div style={styles.container}>
      <ThreeDotMenuCellRenderer
        isMultiSelectActive={isMultiSelectActive}
        statusText={statusText}
        actions={[
          ...singleActions,
          ...(!isSummaryRow ? [{ label: 'EDIT', state: editModeActive ? 'disabled' : 'enabled', icon: 'fa-pencil', onClick: onEditClick }] : []),
        ]}
        isSelected={isSelected(data.id)}
        toggleSelected={() => handleSelect()}
        isClosed={isClosed}
        onOpen={handleOpen}
      />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
};
export default AssetsThreeDotMenuCellRenderer;
