import { Box, Button, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import distance from '@turf/distance';
import { LineString, Polygon, Properties, Units } from '@turf/helpers';
import { Feature as MapFeature } from 'geojson';
import * as MapboxGl from 'mapbox-gl';
import React, { Dispatch, SetStateAction, useMemo, useRef, useState, useContext, useEffect } from 'react';
import { Feature, Layer, Marker, Source } from 'react-mapbox-gl';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { AssetType, MapAssetType } from '../../../contexts/AggregatesContext/types';
import {
  useConfig,
  useCurrentLocation,
  getUnitAbbreviation,
  round,
  MapBoundsContext,
} from '@terragotech/gen5-shared-components';
import Map, { MapItem, MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL } from '../../Map/component/Map';
import { BaseLocationMapProps, useLocationStyles } from './CommonEditorUtils';
import { useRecordType } from '../../../contexts/recordTypeContext';
import { getFitBounds } from './measurementUtils';
interface TGPolylineEditorProps extends BaseLocationMapProps {
  lineLocation: GeoJSON.LineString | null | undefined;
  setLineLocation: Dispatch<SetStateAction<GeoJSON.LineString | null | undefined>>;
  assetData: MapAssetType[];
  setMapBounds?: any;
}

export const TGPolylineEditor: React.FC<TGPolylineEditorProps> = props => {
  const { lineLocation, setLineLocation, setIsValidLocation, assetData } = props;
  const [pointToDelete, setPointToDelete] = useState<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);
  // Need a ref because the onClick passed to react-mapbox-gl will not update when state changes
  const pointToDeleteRef = useRef<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);
  const hoverOnFeature = useRef(false);
  const { currentZoomLevel: contextCurrentZoomLevel } = useContext(MapBoundsContext);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(contextCurrentZoomLevel);
  const [mapViewBox, setMapViewBox] = useState<MapFeature<Polygon, Properties>>();
  const [desiredZoom, setDesiredZoom] = useState(18);
  const { aggregateDefinitions, line } = useConfig();
  const { geographic } = useConfig();
  const { polylineRoundingPrecision, polylineUnitOfMeasurement } = geographic || {
    polylineRoundingPrecision: undefined,
    polylineUnitOfMeasurement: undefined,
  };
  const configRoundingPrecision: 'ones' | 'tenths' | 'hundredths' | 'thousandths' = polylineRoundingPrecision || 'ones';
  const configUnits: Units = polylineUnitOfMeasurement || ('feet' as Units);
  const unitsAbbreviation = getUnitAbbreviation(configUnits);

  const [showMeasurement, setShowMeasurement] = useState(true);

  const { selectedRecordType } = useRecordType();
  useEffect(() => {
    const aggregateDefinition = aggregateDefinitions.find(d => d.queryKey === selectedRecordType);
    setShowMeasurement(
      typeof aggregateDefinition?.showMeasurementOnMap === 'undefined' ||
        aggregateDefinition?.showMeasurementOnMap === null
        ? true
        : aggregateDefinition?.showMeasurementOnMap
    );
  }, [aggregateDefinitions, selectedRecordType]);

  const lineEditColor = line?.editColor && line.editColor.includes('#') ? line.editColor : '#57CCF3';
  const mapItems: Array<MapItem> = assetData
    .filter(
      (asset: AssetType) =>
        asset.id &&
        asset.primaryLocation?.type &&
        asset.primaryLocation?.coordinates &&
        (!asset.recordTypeKey || !aggregateDefinitions.find(d => d.queryKey === asset.recordTypeKey)?.hiddenFromMapView)
    )
    .map(asset => {
      return {
        location: asset.primaryLocation,
        styleKey: asset.symbolKey,
        id: asset.id,
        selected: false,
        aggregateType: asset.recordTypeKey,
      };
    });

  const mapRef = useRef<MapboxGl.Map | null>(null);
  const locationStyles = useLocationStyles();
  const currentLocation = useCurrentLocation();

  const onMapLoad: MapEvent = map => {
    // We slightly adjust this map parameter, so that the pins get rendered properly
    //https://github.com/alex3165/react-mapbox-gl/issues/904#issuecomment-782748173
    setDesiredZoom(18.001);
    mapRef.current = map;
    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = 'crosshair';
      if (lineLocation && lineLocation.coordinates.length > 1) {
        setTimeout(() => mapRef.current?.fitBounds(getFitBounds(lineLocation.coordinates), { animate: false }), 200);
      }
    }
  };

  const updateLineLocation = ({ lng, lat, indexToUpdate }: { lng: number; lat: number; indexToUpdate: number }) => {
    setLineLocation(prevLine => {
      if (prevLine) {
        let newLine = {
          type: 'LineString',
          coordinates: [
            ...prevLine.coordinates.slice(0, indexToUpdate),
            [lng, lat],
            ...prevLine.coordinates.slice(indexToUpdate + 1),
          ],
        } as GeoJSON.LineString;
        return newLine;
      }
    });
    setIsValidLocation(true);
  };

  const addLineLocation = (lng: number, lat: number) => {
    setLineLocation(prevLine => {
      let newLine = {
        type: 'LineString',
        coordinates: [...(prevLine?.coordinates ? prevLine.coordinates : []), [lng, lat]],
      } as GeoJSON.LineString;
      return newLine;
    });
    setIsValidLocation(true);
  };

  const mouseEnterFeature = () => {
    hoverOnFeature.current = true;
    if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'pointer';
  };

  const mouseLeaveFeature = () => {
    hoverOnFeature.current = false;
    if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'crosshair';
  };

  const onClick = (_map: MapboxGl.Map, evt: MapboxGl.EventData) => {
    const { lat, lng } = evt.lngLat;
    if (!hoverOnFeature.current && !pointToDeleteRef.current) {
      addLineLocation(lng, lat);
    } else if (!hoverOnFeature.current && pointToDeleteRef.current) {
      pointToDeleteRef.current = undefined;
      setPointToDelete(undefined);
    }
  };

  const handleDrag = (index: number) => (evt: any) => {
    const { lat, lng } = evt.lngLat;
    updateLineLocation({ lng, lat, indexToUpdate: index });
  };

  const buildSourceDataFromItems = (
    lineString: LineString,
    showPolylineMeasurement: boolean | undefined,
    configUnits: any,
    configRoundingPrecision: any,
    unitsAbbreviation: string
  ): [GeoJSON.FeatureCollection] => {
    let lineMeasurement: any[] = [];
    if (showPolylineMeasurement) {
      let previousLocation: number[] | undefined;
      let totalDistance = 0;
      (lineString?.coordinates || []).forEach((location: number[]) => {
        if (previousLocation) {
          let midPoint = getMidpoint([location, previousLocation]);
          let distanceInUnits = distance(location, previousLocation, { units: configUnits });
          totalDistance += distanceInUnits;
          let roundedDistance = round(distanceInUnits, configRoundingPrecision);
          const measurementItem = {
            geometry: {
              type: 'Point',
              coordinates: midPoint,
            } as GeoJSON.Point,
            type: 'Feature',
            properties: {
              measurement: `${roundedDistance}${unitsAbbreviation}`,
            },
          } as const;
          lineMeasurement.push(measurementItem);
        }
        previousLocation = location;
      });
      const measurementItem = {
        geometry: {
          type: 'Point',
          coordinates: lineString?.coordinates[0],
        } as GeoJSON.Point,
        type: 'Feature',
        properties: {
          measurement: `Total: ${round(totalDistance, configRoundingPrecision)}${unitsAbbreviation}`,
        },
      } as const;
      lineMeasurement.push(measurementItem);
    }
    return [{ type: 'FeatureCollection', features: lineMeasurement }];
  };

  const [lineMeasurement] = useMemo(() => {
    return lineLocation
      ? buildSourceDataFromItems(lineLocation, showMeasurement, configUnits, configRoundingPrecision, unitsAbbreviation)
      : [null, null, null];
  }, [lineLocation]);

  const editLineGeoJsonSource = useMemo(() => {
    return {
      type: 'geojson',
      data: lineLocation || undefined,
      maxzoom: Math.max(MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL),
    };
  }, [lineLocation]);

  const lineMeasurementGeoJsonSource = useMemo(() => {
    return {
      type: 'geojson',
      data: lineMeasurement,
      maxzoom: Math.max(MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL),
    };
  }, [lineMeasurement]);

  const midpoints = useMemo(() => {
    if (lineLocation) {
      const getMidPoints = () => {
        let midPoints: number[][] = [];
        let previousLocation: number[] | undefined;
        lineLocation.coordinates.forEach((location: number[]) => {
          if (previousLocation) {
            midPoints.push(getMidpoint([location, previousLocation]));
          }
          previousLocation = location;
        });
        return midPoints;
      };

      return getMidPoints().map((location, index) => (
        <Feature
          key={index}
          coordinates={location}
          onMouseEnter={mouseEnterFeature}
          onMouseLeave={mouseLeaveFeature}
          onClick={() => {
            setLineLocation(prevLine => {
              if (mapRef.current) {
                if (prevLine) {
                  let newLine = {
                    ...prevLine,
                    coordinates: [
                      ...prevLine.coordinates.slice(0, index + 1),
                      location,
                      ...prevLine.coordinates.slice(index + 1, prevLine.coordinates.length),
                    ],
                  };
                  return newLine;
                }
              } else {
                return prevLine;
              }
            });
          }}
        />
      ));
    }
  }, [mapRef.current, setLineLocation, lineLocation]);

  const lineLayerLayout = {
    'line-join': 'round',
    'line-cap': 'round',
  };
  const lineLayerPaint = {
    'line-color': lineEditColor,
    'line-width': 4,
  };

  const midpointLayerPaint = {
    'circle-color': '#FFF',
    'circle-radius': 6,
    'circle-stroke-color': lineEditColor,
    'circle-stroke-width': 1,
  };
  const linePointPaint = {
    'circle-color': '#FFF',
    'circle-radius': 10,
    'circle-stroke-color': lineEditColor,
    'circle-stroke-width': 3,
  };

  const lineMarkerLayerPaint = {
    'text-color': '#FFFFFF',
    'text-halo-color': 'black',
    'text-halo-width': 2,
  };
  const lineMarkerLayerLayout = {
    'text-field': ['get', 'measurement'],
    'text-letter-spacing': 0.1,
    'text-size': 14,
    'text-offset': [0, -1],
    'text-justify': 'center',
    'text-font': ['Arial Unicode MS Bold'],
  };

  const desiredCenter = useMemo(() => {
    // If there's one point, we zoom to it. Otherwise, we handle the bounds of all points in `onMapLoad`.
    if (lineLocation?.coordinates?.length === 1) {
      return lineLocation.coordinates[0] as [number, number];
    }
    return undefined;
  }, []);

  return (
    <>
      <Box className={`${locationStyles.mapContainer} ${locationStyles.formPolygonMapContainer}`}>
        <Map
          desiredCenter={desiredCenter}
          desiredZoom={desiredZoom}
          items={mapItems}
          onStyleLoad={onMapLoad}
          onClick={onClick}
          setMapViewBox={setMapViewBox}
          mapViewBox={mapViewBox}
          setMapBounds={props.setMapBounds}
          currentZoomLevel={currentZoomLevel}
          setCurrentZoomLevel={setCurrentZoomLevel}
        >
          <Source id="editLineSource" geoJsonSource={editLineGeoJsonSource} />
          <Source id="lineMeasurementSource" geoJsonSource={lineMeasurementGeoJsonSource} />
          <Layer paint={linePointPaint} type="circle" id="edit line layer vertices">
            {lineLocation?.coordinates.map((coords: number[], index: number) => {
              return (
                <Feature
                  coordinates={coords}
                  key={index}
                  draggable
                  onMouseEnter={mouseEnterFeature}
                  onMouseLeave={mouseLeaveFeature}
                  onDrag={handleDrag(index)}
                  onClick={() => {
                    if (!pointToDelete) {
                      setPointToDelete({ coordinates: coords, indexOfCoords: index });
                      pointToDeleteRef.current = { coordinates: coords, indexOfCoords: index };
                    } else {
                      pointToDeleteRef.current = undefined;
                      setPointToDelete(undefined);
                    }
                  }}
                />
              );
            })}
          </Layer>
          <Layer
            layout={lineLayerLayout}
            paint={lineLayerPaint}
            type="line"
            id="edit line layer"
            sourceId="editLineSource"
            before="edit line layer vertices"
          />
          <Layer paint={midpointLayerPaint} type="circle" id="midpoints">
            {/* memoized midpoint values, recalculates as needed  */}
            {midpoints}
          </Layer>
          <Layer
            type="symbol"
            id={'polyline-editor-measurement'}
            sourceId="lineMeasurementSource"
            paint={lineMarkerLayerPaint}
            layout={lineMarkerLayerLayout}
          />
          {pointToDelete && (
            <Marker
              anchor={'bottom'}
              coordinates={pointToDelete.coordinates}
              onMouseEnter={mouseEnterFeature}
              onMouseLeave={mouseLeaveFeature}
              style={{ marginTop: 50 }}
            >
              <Button
                variant="contained"
                startIcon={<DeleteIcon />}
                style={{ backgroundColor: '#FFFFFF', color: '#FF0000' }}
                onClick={() => {
                  //TODO: Make a removeFromLineLocation
                  setLineLocation(prevLine => {
                    let newLine = {
                      type: 'LineString',
                      coordinates: [...(prevLine?.coordinates ? prevLine.coordinates : [])],
                    } as GeoJSON.LineString;
                    newLine.coordinates.splice(pointToDelete.indexOfCoords, 1);
                    return newLine;
                  });
                  setIsValidLocation(true);
                  pointToDeleteRef.current = undefined;
                  setPointToDelete(undefined);
                  hoverOnFeature.current = false;
                  if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'crosshair';
                }}
              >
                Delete
              </Button>
            </Marker>
          )}
        </Map>
        {currentLocation ? (
          <Button
            className={`${locationStyles.captureLocationBtn} ${locationStyles.bottomTopRightBtn}`}
            variant="contained"
            onClick={() => {
              addLineLocation(currentLocation.longitude, currentLocation.latitude);
            }}
          >
            capture device location
          </Button>
        ) : (
          <Tooltip
            className={`${locationStyles.captureLocationBtnDis} ${locationStyles.bottomTopRightBtn}`}
            title="This device does not have a location"
            placement="top"
          >
            <Button color="default" variant="contained">
              capture device location
            </Button>
          </Tooltip>
        )}
      </Box>
    </>
  );
};

const getMidpoint = (coords: number[][]): [number, number] => {
  const xMidPoint = (coords[0][0] + coords[1][0]) / 2;
  const yMidPoint = (coords[0][1] + coords[1][1]) / 2;
  return [xMidPoint, yMidPoint];
};
